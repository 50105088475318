import StoreManager from "../redux/";

const SessionHelper = {

    get: (key) => {
        var store = StoreManager.getStore();
        return store.getState().session[key];
    },

    set: (key, value) => {
        var store = StoreManager.getStore();
        store.getState().session[key] = value;
    },


    isNetworkConnected(){
        return true;
    }
};

export default SessionHelper;
