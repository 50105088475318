import apisauce from "apisauce";
import Cache from "../cache/";
import _ from "lodash";

import AppConfig from "../config/AppConfig";
import StoreManager from "../redux/";
import DebugLogger from "../utils/DebugLogger";
import * as Helper from "../utils/Helper";
import { Actions as SessionActions } from "../redux/SessionRedux";
import i18n from "i18next";
import Constants from "../config/Constants";

function isMobileWeb() {
    return window.location.pathname.startsWith('/mobile/');
}

export class ApiService {
    constructor() {
        var base64 = require("base-64");
        const hash = base64.encode(AppConfig.APP_CLIENT_ID + ":" + AppConfig.APP_CLIENT_SECRET);
        this.basicAuth = "Basic " + hash;

        // once we get an access token, we will use access token whenever possible
        this.bearerAuth = null;

        let locale = Helper.getLocale(i18n.language);

        this.api = apisauce.create({
            // base URL is read from the "constructor"
            baseURL: AppConfig.APP_SERVICE_BASE,
            // here are some default headers
            headers: {
                "Cache-Control": "no-cache",
                // "Accept-Language": I18n.locale + ", " + I18n.locale.substr(0, 2)
                "Accept-Language": locale + ", " + locale.substr(0, 2),
            },
            // 300 second timeout...
            timeout: 300000,
        });

        this.api.addResponseTransform(response => {
            response.fromCache = true;
        });

        this.apiWithOffline = apisauce.create({
            // base URL is read from the "constructor"
            baseURL: AppConfig.APP_SERVICE_BASE,
            // here are some default headers
            headers: {
                "Cache-Control": "no-cache",
                // "Accept-Language": I18n.locale + ", " + I18n.locale.substr(0, 2)
                "Accept-Language": locale + ", " + locale.substr(0, 2),
            },
            // 6 second timeout...
            timeout: 6000,
        });

        this.apiWithOffline.addResponseTransform(response => {
            response.fromCache = true;
        });

        this.replayApiErrorSauce = apisauce.create({
            // base URL is read from the "constructor"
            baseURL: AppConfig.APP_SERVICE_BASE,
            // here are some default headers
            headers: {
                "Cache-Control": "no-cache",
                // "Accept-Language": I18n.locale + ", " + I18n.locale.substr(0, 2)
                "Accept-Language": locale + ", " + locale.substr(0, 2),
            },
            // 300 second timeout...
            timeout: 300000,
        });

        this.keyCache = new Cache(50000);
        this.dataCache = new Cache(50000);
        // define how many ms we wait before we start to check validity of cached data
        this.validityCheckDelay = 1000; // 5 seconds

        // define how many ms we wait before we start to check validity of cached data
        this.validityCheckDelay = 5000; // 5 seconds

        this.tryRefreshTokenTimes = 0;
        this.ignoreConvertingCamelCaseRequestArr = ["v3", "v4", "v5", "v6"]; //ignore converting request data from camelCase to snakeCase
    }

    setBaseURL(baseURL) {
        this.api.setBaseURL(baseURL);
        this.apiWithOffline.setBaseURL(baseURL);
        this.replayApiErrorSauce.setBaseURL(baseURL);
    }

    forceGet(apiUrl, jsonData, successCallback, failureCallback) {
        this._ajax("get", apiUrl, jsonData, true, successCallback, failureCallback);
    }

    get(apiUrl, jsonData, successCallback, failureCallback) {
        this._ajax("get", apiUrl, jsonData, false, successCallback, failureCallback);
    }

    forcePost(apiUrl, jsonData, successCallback, failureCallback) {
        this._ajax("post", apiUrl, jsonData, true, successCallback, failureCallback);
    }

    post(apiUrl, jsonData, successCallback, failureCallback) {
        this._ajax("post", apiUrl, jsonData, false, successCallback, failureCallback);
    }

    _ajax(method, apiUrl, jsonData, refreshForced, successCallback, failureCallback) {
        DebugLogger.log("Ajax: " + apiUrl + " is called.");

        //let base = this;
        // update bearer authorization header
        this.bearerAuth = this._getBearerAuthorizationHeader();

        // set request header for authentication
        this.api.setHeader("Authorization", this.bearerAuth ? this.bearerAuth : this.basicAuth);
        this.api.setHeader("Content-Type", "application/json");

        let locale = Helper.getLocale(i18n.language);
        this.api.setHeader("Accept-Language", locale + ", " + locale.substr(0, 2));

        // set a empty object for null or undefined jsonData
        if (!jsonData) {
            jsonData = {};
        }

        // add a clientType so that service knows where the call is coming from
        // just in case we may need some special logic to handle requests from different
        // clients
        // clientType: 0 - web, 1 - app
        jsonData.clientVersion = AppConfig.APP_CLIENT_VERSION;
        jsonData.clientBuild = isMobileWeb() ? AppConfig.MOBILE_APP_CLIENT_BUILD : AppConfig.APP_CLIENT_BUILD;
        jsonData.clientType = 0;
        jsonData.timeZone = Helper.getTimeZone();
        jsonData.clientOS = "Web";

        // //we also need to convert request data from camelCase to snakeCase
        // //do not convert after v3
        // if (!_.some(this.ignoreConvertingCamelCaseRequestArr, (el) => _.includes(_.toLower(apiUrl), _.toLower(el)))) {
        //     jsonData = Helper.convertCamelCaseKeysToSnakeCase(jsonData);
        // }

        // build json string based on json data
        const jsonDataString = JSON.stringify(jsonData);
        // create a unique post identifier based on serivce url and json data
        const uid = apiUrl + "@" + jsonDataString;
        DebugLogger.log("_ajax is called. {apiUrl: " + apiUrl + ", jsonData: " + jsonDataString + "}");

        // indicate if we need to get data from server
        if (!refreshForced) {
            // find the cached key based on the unique identifier of the request
            const cachedKey = this.keyCache.get(uid);
            // append cachedKey as a request parameter and get value based on key
            let value = null;
            if (cachedKey) {
                jsonData.cachedKey = cachedKey;
                value = this.dataCache.get(cachedKey);
            } else {
                jsonData.cachedKey = null;
            }
            // create a unique post identifier based on serivce url and json data
            if (value) {
                if (successCallback) successCallback(value);
                setTimeout(() => {
                    this._callAjax(
                        method,
                        apiUrl,
                        jsonData,
                        (response, pCacheKey) => {
                            let tCacheKey = pCacheKey;
                            if (!tCacheKey && response) {
                                tCacheKey = response.cachedKey;
                            }
                            // if any new data, then call success callback
                            // otherwise, do nothing
                            if (cachedKey !== tCacheKey) {
                                DebugLogger.log("Cached data is updated by others. {uid: " + uid + "}");

                                // remove old cached data since we don't need it any more.
                                this.dataCache.remove(cachedKey);

                                DebugLogger.log("Old cached data is removed from the cache. {uid: " + uid + "}");

                                if (tCacheKey !== null && tCacheKey !== "") {
                                    // update the cache key to the key cache
                                    this.keyCache.set(uid, tCacheKey);

                                    // update the data to the data cache
                                    this.dataCache.set(tCacheKey, response);

                                    DebugLogger.log("Cached data is updated for this service call. {uid: " + uid + ", cachedKey:" + tCacheKey + "}");
                                }

                                // call sucdess callback for newly updated data
                                if (successCallback) successCallback(response);
                            } else {
                                DebugLogger.log("Cached data is up-to-date. {uid: " + uid + "}");
                            }
                        },
                        failureCallback, 0
                    );
                }, this.validityCheckDelay);

                return;
            }
        }

        this._callAjax(
            method,
            apiUrl,
            jsonData,
            (response, cacheKey) => {
                let tCacheKey = cacheKey;
                if (!tCacheKey && response) {
                    tCacheKey = response.cachedKey;
                }
                if (tCacheKey) {
                    // add new cached key to the key cache
                    this.keyCache.set(uid, tCacheKey);

                    // add new data to the data cache
                    this.dataCache.set(tCacheKey, response);

                    DebugLogger.log("Data is added to the cache for this service call. {uid: " + uid + ", cachedKey:" + tCacheKey + ", cache size: " + this.dataCache.cache.size + "}");
                }

                if (successCallback) successCallback(response);
            },
            failureCallback, 0
        );
    }

    _callAjax(method, apiUrl, jsonData, successCallback, failureCallback, retriedTimes) {
        let base = this;
        // call api directly to reutrn data
        this.api[method](apiUrl, jsonData)
            .then(response => {
                // response = {};
                // response.li_text_str = "hello world.";
                // response.li_users = ["mike", "john", "frank"];
                // response.xi_list = new Array();
                // response.xi_list[0] = { name_first: "wake", age_int: 12 };
                // response.xi_list[1] = { id_number: 1910901, status_text: "freshman" };

                // response = Helper.convertSnakeCaseKeysToCamelCase(response);

                // convert snakeCase to camelCase
                response = Helper.convertSnakeCaseKeysToCamelCase(response);

                if (response.ok) {
                    // this.dataCache.set(apiKey, response.data);

                    var returnedData = response.data;
                    if (_.has(returnedData, "isDone")) {
                        // if returned data is ResultOutVO
                        if (returnedData.isDone) {
                            //put cachedkey as 2nd param since here we return the data of ApiResponse
                            //AIC-9-354 如果是冻结的老师或代理账号则直接退出登录
                            if(returnedData.data && returnedData.data.isDeactivatedAccount){
                                let store = StoreManager.getStore();
                                store.dispatch(SessionActions.logout());
                            }
                            if (successCallback) successCallback(returnedData.data, returnedData.cachedKey);
                        } else {
                            if(returnedData.code === Constants.CODES.NOT_EXIST_CURUSER)//current user is not exist 
                                StoreManager.getStore().dispatch(SessionActions.logout());
                            else if (failureCallback) failureCallback(this._formalizeError(returnedData));
                        }
                    } else {
                        //other case no need
                        if (successCallback) successCallback(response.data);
                    }
                } else {
                    if (response.problem && (response.problem === apisauce.NETWORK_ERROR || response.problem === apisauce.CONNECTION_ERROR || response.problem === apisauce.TIMEOUT_ERROR)) {
                        if (retriedTimes < 1) {
                            setTimeout(() => {
                                retriedTimes = retriedTimes + 1;
                                this._callAjax(method, apiUrl, jsonData, successCallback, failureCallback, retriedTimes);
                            }, 3000);
                        } else {
                            if (failureCallback) failureCallback(this._formalizeError(response.data, response.problem, apiUrl, jsonData));
                        }
                    } else if (response.status === 403 /* forbidden */) {
                        // probably access token is expired, we need to use refresh token to get a new
                        // access token so that user can continue access the app without any issue

                        // try to refresh token here
                        let store = StoreManager.getStore();
                        let authorization = store.getState().session.authorization;
                        //if we already has tried 3 times here, we just assume it as that we can not refresh token
                        if (authorization && authorization.refreshToken) {
                            DebugLogger.log("Response status 403.Try Refresh Token", authorization);
                            //set specific headers for refresh token
                            base.api.setHeaders({
                                Authorization: base.basicAuth,
                                "Content-Type": "application/x-www-form-urlencoded",
                            });

                            base.api["post"]("/OAuth/Token.ashx", `grant_type=refresh_token&scope=iml.user&refresh_token=${authorization.refreshToken}`)
                                .then(refreshResponse => {
                                    if (refreshResponse.ok && refreshResponse.data) {
                                        DebugLogger.groupMessage("Refresh Token Done", refreshResponse);
                                        refreshResponse = Helper.convertSnakeCaseKeysToCamelCase(refreshResponse);
                                        //dispatch action to set and save in session
                                        store.dispatch(SessionActions.receiveRefreshedToken(refreshResponse.data));
                                        //re send previous request
                                        DebugLogger.log("ReSend Previous Request");

                                        base._ajax(method, apiUrl, jsonData, true, successCallback, failureCallback);
                                    } else {
                                        store.dispatch(SessionActions.logout());

                                        DebugLogger.groupMessage("Refresh Token Failed", refreshResponse);
                                        if (failureCallback) failureCallback(this._formalizeError(response.data, response.problem));
                                    }
                                })
                                .catch(e => {
                                    store.dispatch(SessionActions.logout());
                                    if (failureCallback) failureCallback(this._formalizeError(null, e.message));
                                });
                        } else {
                            // if (this.tryRefreshTokenTimes >= 3) {
                            //     // we need to clear the session data and ask user to log in again to get token in this case
                            //     // so that we can avoid some issues with token refreshing.
                            //     store.dispatch(SessionActions.logout());
                            // }
                            store.dispatch(SessionActions.logout());
                            if (failureCallback) failureCallback(this._formalizeError(response.data, response.problem));
                        }
                    } else {
                        if (failureCallback) failureCallback(this._formalizeError(response.data, response.problem));
                    }
                }
            })
            .catch(e => {
                if (failureCallback) failureCallback(this._formalizeError(null, e.message));
            });
    };

    replayApiError = (method, authorization, headers, apiUrl, jsonData, successCallback, failureCallback) => {
        DebugLogger.log("replayApiError: " + apiUrl + " is called.");

        method = _.lowerCase(method);
        //let base = this;
        // update bearer authorization header
        const bearerAuth = authorization ? "Bearer " + authorization.accessToken : null;

        try {
            const headerJson = JSON.parse(headers);
            this.replayApiErrorSauce.setHeaders(headerJson);
            jsonData = JSON.parse(jsonData);
        } catch (e) {}

        // set a empty object for null or undefined jsonData
        if (!jsonData) {
            jsonData = {};
        }
        // set request header for authentication
        this.replayApiErrorSauce.setHeader("Authorization", bearerAuth ? bearerAuth : this.basicAuth);

        // call api directly to reutrn data
        this.replayApiErrorSauce[method](apiUrl, jsonData)
            .then(response => {
                // convert snakeCase to camelCase
                response = Helper.convertSnakeCaseKeysToCamelCase(response);

                if (response.ok) {
                    // this.dataCache.set(apiKey, response.data);

                    var returnedData = response.data;
                    if (_.has(returnedData, "isDone")) {
                        // if returned data is ResultOutVO
                        if (returnedData.isDone) {
                            //put cachedkey as 2nd param since here we return the data of ApiResponse
                            if (successCallback) successCallback(returnedData.data, returnedData.cachedKey);
                        } else {
                            if (failureCallback) failureCallback(this._formalizeError(returnedData));
                        }
                    } else {
                        //other case no need
                        if (successCallback) successCallback(response.data);
                    }
                } else {
                    if (failureCallback) failureCallback(this._formalizeError(response.data, response.problem));
                }
            })
            .catch(e => {
                if (failureCallback) failureCallback(this._formalizeError(null, e.message));
            });
    };

    getWithOffline(apiUrl, jsonData, successCallback, failureCallback, offlineCallback, forceNoWait) {
        return this._ajaxWithOffline("get", apiUrl, jsonData, successCallback, failureCallback, offlineCallback, forceNoWait);
    }

    postWithOffline(apiUrl, jsonData, successCallback, failureCallback, offlineCallback, forceNoWait) {
        return this._ajaxWithOffline("post", apiUrl, jsonData, successCallback, failureCallback, offlineCallback, forceNoWait);
    }

    _ajaxWithOffline(method, apiUrl, jsonData, successCallback, failureCallback, offlineCallback, forceNoWait) {
        DebugLogger.log("AjaxWithOffline: " + apiUrl + " is called.");

        let base = this;
        // update bearer authorization header
        this.bearerAuth = this._getBearerAuthorizationHeader();

        // set request header for authentication
        this.apiWithOffline.setHeader("Authorization", this.bearerAuth ? this.bearerAuth : this.basicAuth);

        let locale = Helper.getLocale(i18n.language);
        this.apiWithOffline.setHeader("Accept-Language", locale + ", " + locale.substr(0, 2));

        // set a empty object for null or undefined jsonData
        if (!jsonData) {
            jsonData = {};
        }

        // add a clientType so that service knows where the call is coming from
        // just in case we may need some special logic to handle requests from different
        // clients
        // clientType: 0 - web, 1 - mobile
        jsonData.clientVersion = AppConfig.APP_CLIENT_VERSION;
        jsonData.clientBuild = isMobileWeb() ? AppConfig.MOBILE_APP_CLIENT_BUILD : AppConfig.APP_CLIENT_BUILD;
        jsonData.clientType = 0;
        jsonData.timeZone = Helper.getTimeZone();
        // jsonData.clientOS = Platform.OS;

        //we also need to convert request data from camelCase to snakeCase
        //do not convert after v3
        if (!_.some(this.ignoreConvertingCamelCaseRequestArr, el => _.includes(_.toLower(apiUrl), _.toLower(el)))) {
            jsonData = Helper.convertCamelCaseKeysToSnakeCase(jsonData);
        }

        forceNoWait = forceNoWait || false;
        let returned = false;
        if (forceNoWait) {
            //force call offline callback in case the time out do not happen
            //we use this if we always want it to call a callback
            setTimeout(() => {
                //then call offline callback tell that we need go offline mode
                if (!returned && offlineCallback) {
                    offlineCallback({ problem: "TIMEOUT_ERROR" });
                }
            }, 7000);
        }

        // call api directly to reutrn data
        return this.apiWithOffline[method](apiUrl, jsonData)
            .then(response => {
                returned = true;
                // response = {};
                // response.li_text_str = "hello world.";
                // response.li_users = ["mike", "john", "frank"];
                // response.xi_list = new Array();
                // response.xi_list[0] = { name_first: "wake", age_int: 12 };
                // response.xi_list[1] = { id_number: 1910901, status_text: "freshman" };

                // response = Helper.convertSnakeCaseKeysToCamelCase(response);

                // convert snakeCase to camelCase
                response = Helper.convertSnakeCaseKeysToCamelCase(response);
                //DebugLogger.log("AjaxOffline: response -" );
                //DebugLogger.log(response);
                if (response.ok) {
                    // this.dataCache.set(apiKey, response.data);

                    //reset retry times since we got a response ok here
                    this.tryRefreshTokenTimes = 0;

                    var returnedData = response.data;
                    if (_.has(returnedData, "isDone")) {
                        // if returned data is ResultOutVO
                        if (returnedData.isDone) {
                            if (successCallback) successCallback(returnedData.data);
                        } else {
                            if (failureCallback) failureCallback(this._formalizeError(returnedData));
                        }
                    } else {
                        if (successCallback) successCallback(response.data);
                    }
                } else {
                    //if request failed because of these problem, we suppose now it is offline.
                    //and we put this request into the background offline api call array try to replay it later
                    if (response.problem && (response.problem === apisauce.NETWORK_ERROR || response.problem === apisauce.CONNECTION_ERROR || response.problem === apisauce.TIMEOUT_ERROR)) {
                        DebugLogger.log("AjaxOffline: Problem -" + response.problem);

                        //then call offline callback tell that we need go offline mode
                        if (offlineCallback) {
                            offlineCallback(response);
                        }
                    } else {
                        if (response.status === 403 /* forbidden */) {
                            // probably access token is expired, we need to use refresh token to get a new
                            // access token so that user can continue access the app without any issue

                            // try to refresh token here
                            let store = StoreManager.getStore();
                            let authorization = store.getState().session.authorization;
                            //if we already has tried 3 times here, we just assume it as that we can not refresh token
                            if (authorization && authorization.refreshToken && this.tryRefreshTokenTimes < 3) {
                                DebugLogger.log("Response status 403.Try Refresh Token", authorization);
                                //set specific headers for refresh token
                                base.apiWithOffline.setHeaders({
                                    Authorization: base.basicAuth,
                                    "Content-Type": "application/x-www-form-urlencoded",
                                });
                                //start to try, add tried times
                                base.tryRefreshTokenTimes += 1;
                                base.apiWithOffline["post"]("/OAuth/Token.ashx", `grant_type=refresh_token&scope=iml.user&refresh_token=${authorization.refreshToken}`)
                                    .then(refreshResponse => {
                                        if (refreshResponse.ok) {
                                            DebugLogger.groupMessage("Refresh Token Done", refreshResponse);
                                            refreshResponse = Helper.convertSnakeCaseKeysToCamelCase(refreshResponse);
                                            //dispatch action to set and save in session
                                            store.dispatch(SessionActions.receiveRefreshedToken(refreshResponse.data));
                                            //re send previous request
                                            DebugLogger.log("ReSend Previous Request");

                                            base._ajaxWithOffline(method, apiUrl, jsonData, successCallback, failureCallback, offlineCallback, forceNoWait);
                                        } else {
                                            DebugLogger.groupMessage("Refresh Token Failed", refreshResponse);
                                            if (failureCallback) failureCallback(this._formalizeError(response.data, response.problem));
                                        }
                                    })
                                    .catch(e => {
                                        //then call offline callback tell that we need go offline mode
                                        if (offlineCallback) {
                                            offlineCallback(this._formalizeError(null, e.message));
                                        }
                                    });
                            } else {
                                if (failureCallback) failureCallback(this._formalizeError(response.data, response.problem));
                            }
                        } else {
                            if (failureCallback) failureCallback(this._formalizeError(response.data, response.problem));
                        }
                    }
                }
            })
            .catch(e => {
                returned = true;
                //then call offline callback tell that we need go offline mode
                if (offlineCallback) {
                    offlineCallback(this._formalizeError(null, e.message));
                }
            });
    }

    /**
     * just replay ajax call, no need call the call back
     */
    replayAjaxCall(method, apiUrl, jsonData) {
        /*if(method === "get"){
            //we do not need replay get
            return Promise.resolve(null);
        }*/
        DebugLogger.log("ReplayAjaxCall: " + apiUrl + " is called.");

        // update bearer authorization header
        this.bearerAuth = this._getBearerAuthorizationHeader();

        // set request header for authentication
        this.apiWithOffline.setHeader("Authorization", this.bearerAuth ? this.bearerAuth : this.basicAuth);

        let locale = Helper.getLocale(i18n.language);
        this.apiWithOffline.setHeader("Accept-Language", locale + ", " + locale.substr(0, 2));

        // set a empty object for null or undefined jsonData
        if (!jsonData) {
            jsonData = {};
        }

        // add a clientType so that service knows where the call is coming from
        // just in case we may need some special logic to handle requests from different
        // clients
        // clientType: 0 - web, 1 - app
        jsonData.clientVersion = AppConfig.APP_CLIENT_VERSION;
        jsonData.clientBuild = isMobileWeb() ? AppConfig.MOBILE_APP_CLIENT_BUILD : AppConfig.APP_CLIENT_BUILD;
        jsonData.clientType = 0;
        jsonData.timeZone = Helper.getTimeZone();
        // jsonData.clientOS = Platform.OS;

        //we also need to convert request data from camelCase to snakeCase
        //do not convert after v3
        if (!_.some(this.ignoreConvertingCamelCaseRequestArr, el => _.includes(_.toLower(apiUrl), _.toLower(el)))) {
            jsonData = Helper.convertCamelCaseKeysToSnakeCase(jsonData);
        }

        return new Promise((resolve, reject) => {
            this.apiWithOffline[method](apiUrl, jsonData)
                .then(response => {
                    if (response.ok) {
                        let returnedData = response.data;
                        //we only treat the condition of call success callback as success now
                        if (_.has(returnedData, "isDone")) {
                            // if returned data is ResultOutVO
                            if (returnedData.isDone) {
                                resolve(response);
                            } else {
                                DebugLogger.log("ReplayRequest: failureCallback");
                                DebugLogger.log(this._formalizeError(returnedData));
                                reject(this._formalizeError(returnedData));
                            }
                        } else {
                            resolve(response);
                        }
                    } else {
                        DebugLogger.log("ReplayRequest: Problem");
                        DebugLogger.log(response);
                        reject(response);
                    }
                })
                .catch(e => {
                    DebugLogger.log("ReplayRequest: Problem");
                    DebugLogger.log(e);
                    reject(e);
                });
        });
    }

    _getBearerAuthorizationHeader() {
        var store = StoreManager.getStore();
        var authorization = store.getState().session.authorization;
        if (authorization && authorization.accessToken) {
            return "Bearer " + authorization.accessToken;
        }

        return null;
    }

    _isAuthenticatedBySSO() {
        var store = StoreManager.getStore();
        return store.getState().session.isSSO ? store.getState().session.isSSO : false;
    }

    _getCurrentRouteName() {
        // TODO
        return "";
    }

    postFileToServer(apiUrl, formData, successCallback, failureCallback) {
        apiUrl = this.api.getBaseURL() + apiUrl;

        // update bearer authorization header
        this.bearerAuth = this._getBearerAuthorizationHeader();

        // set a empty FormData object for null or undefined formData
        if (!formData) {
            formData = new FormData();
        }

        // add a clientType so that service knows where the call is coming from
        // just in case we may need some special logic to handle requests from different
        // clients
        // clientType: 0 - web, 1 - app
        formData.append("clientVersion", AppConfig.APP_CLIENT_VERSION);
        formData.append("clientBuild", isMobileWeb() ? AppConfig.MOBILE_APP_CLIENT_BUILD : AppConfig.APP_CLIENT_BUILD);
        formData.append("clientType", 0);
        formData.append("timeZone", Helper.getTimeZone());
        // formData.append("clientOS", Platform.OS);

        let locale = Helper.getLocale(i18n.language);
        fetch(apiUrl, {
            method: "POST",
            processData: false,
            contentType: false,
            headers: {
                //"Content-Type": "multipart/form-data;charset=utf-8",
                "Cache-Control": "no-cache",
                // "Accept-Language": I18n.locale + ", " + I18n.locale.substr(0, 2),
                "Accept-Language": locale + ", " + locale.substr(0, 2),
                Authorization: this.bearerAuth ? this.bearerAuth : this.basicAuth,
            },
            body: formData,
        })
            .then(response => response.json())
            .then(response => (response = Helper.convertSnakeCaseKeysToCamelCase(response)))
            .then(responseData => {
                if (responseData.isDone) {
                    if (successCallback) successCallback(responseData.data);
                } else {
                    DebugLogger.log("_postFileApi err", responseData.message);
                    if (failureCallback) failureCallback(this._formalizeError(responseData.message));
                }
            })
            .catch(err => {
                DebugLogger.log("_postFileApi err", err);
            });
    }

    // postFileToMessageCenter(formData, width, height, successCallback, failureCallback) {
    //     var apiUrl = AppConfig.SITE_BASE + "/MessageCenterUpload.ashx?";
    //     if (width > 0 && height > 0)
    //         apiUrl += `w=${width}&h=${height}`;

    //     // update bearer authorization header
    //     this.bearerAuth = this._getBearerAuthorizationHeader();

    //     // set request header for authentication
    //     let locale = Helper.getLocale(i18n.language);
    //     fetch(apiUrl, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "multipart/form-data;charset=utf-8",
    //             "Cache-Control": "no-cache",
    //             // "Accept-Language": I18n.locale + ", " + I18n.locale.substr(0, 2),
    //             "Accept-Language": locale + ", " + locale.substr(0, 2),
    //             "Authorization": this.bearerAuth ? this.bearerAuth : this.basicAuth
    //         },
    //         body: formData,
    //     }).then((response) => response.json())
    //         .then((responseData) => {
    //             var newResponse = { data: responseData.Data, message: responseData.Message };
    //             if (responseData.IsDone) {
    //                 if (successCallback) successCallback(newResponse);
    //             } else {
    //                 DebugLogger.log("_postFileApi err", responseData.Message);
    //                 if (failureCallback) failureCallback(newResponse);
    //             }
    //         })
    //         .catch((err) => {
    //             DebugLogger.log("_postFileApi err", err);
    //         });
    // }

    _formalizeError(data, problem) {
        var message = "Unknow error.";
        if (problem && problem !== "") {
            message = problem;
        }

        if (!data) {
            return { code: 1, message: message };
        }

        if (_.isString(data)) {
            return { code: 1, message: data };
        }

        var error = { ...data };

        if (!_.has(error, "code")) {
            error.code = 1; // Unknow
        }

        if (!_.has(error, "message")) {
            error.message = message;
        }

        return error;
    }
}

export default new ApiService();
