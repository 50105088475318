import LRUCache from "./LRU";

class Cache {
    constructor(limit) {
        this.cache = new LRUCache(limit);
    }

    get(key) {
        return this.cache.get(key);
    }

    set(key, val) {
        this.cache.set(key, val);
    }

    remove(key) {
        this.cache.remove(key);
    }
}

export default Cache;