// import { GoogleAnalyticsTracker } from "react-native-google-analytics-bridge";

import DebugLogger from "../../utils/DebugLogger";
import Analytics from "../../utils/Analytics";
// import SessionHelper from "../../utils/SessionHelper";

// let tracker = new GoogleAnalyticsTracker("UA-124203308-3");

const screenTracking = store => next => action => {
    let result = next(action);

    //var state = store.getState();
    DebugLogger.log("Redux: " + action.type + " is called.");

    try {
        if (action.type.endsWith("loadScreenViewSuccess")) {
            DebugLogger.log("View: " + action.type + " is loaded.");

            if (!action.data.analytics) {
                throw new Error("Analytics data is not returned for screen (" + action.type + ")");
            }

            Analytics.trackScreenView(action.data.analytics.uid, action.type, action.data.analytics.userProps, action.data.analytics.pageProps);
        }
    } catch (err) {
        DebugLogger.log("Screen view is NOT sent successfully due to " + err);
    }

    return result;
};

export default screenTracking;