import UIDs from "../../../UIDs";

const uid = UIDs.layouts.admin;

const ActionTypes = {
    LOAD_SCREEN_VIEW_ASYNC: `${uid}.loadScreenViewAsync`,
    LOAD_SCREEN_VIEW_SUCCESS: `${uid}.loadScreenViewSuccess`,
    LOAD_SCREEN_VIEW_FAILURE: `${uid}.loadScreenViewFailure`,
    LOGOUT: `${uid}.logout`,
    TRY_LOAD_SESSION: `${uid}.tryLoadSession`,
    SELECT_PARTNER: `${uid}.selectPartner`  
};

export { ActionTypes };
