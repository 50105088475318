const __DEV__ = true;
const DebugLogger = {

    log: (message) => {
        /* eslint-disable */

        if (__DEV__) {
            console.log(message);
        }

        /* eslint-enable */
    },
    
    groupMessage: (groupTitle, message) => {
        /* eslint-disable */

        if (__DEV__) {
            if (console.groupCollapsed && console.groupEnd) {
                console.groupCollapsed(
                    `%c ${groupTitle}`,
                    'fontWeight: bold, color:"black"'
                );
                console.log(message);
                console.groupEnd();
            }
        }

        /* eslint-enable */
    },

    throw: (message) => {
        if (__DEV__) {
            throw new Error(message);
        }
    }
};

export default DebugLogger;