import UIDs from "../../../../UIDs";

const uid = UIDs.screens.account.login;

const ActionTypes = {
    LOGIN_ASYNC: `${uid}.loginAsync`,
    LOGIN_SUCCESS: `${uid}.loginSuccess`,
    LOGIN_FAILURE: `${uid}.loginFailure`,
    
    CHANGE_PASSWORD_ASYNC: `${uid}.changePasswordAsync`,
    CHANGE_PASSWORD_SUCCESS: `${uid}.changePasswordSuccess`,
    CHANGE_PASSWORD_FAILURE: `${uid}.changePasswordFailure`, 

    TOGGLE_MODAL: `${uid}.toggleModal`,
    UPDATE_SETTINGS: `${uid}.updateSettings`,
};

export { ActionTypes };
