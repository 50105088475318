import _ from "lodash";

import DebugLogger from "../utils/DebugLogger";
import StoreManager from "../redux/";
import TaskHelper,{  TaskStates } from "../utils/TaskHelper"

const taskRoundSec = 5;

class BackgroundTaskService {
    // example
    /*taskQueue = {
        "TASK_TYPE": {
            isProcessing: false,
            queue: [
                {
                    id: "",
                    data: "",
                    type:"TaskTypes.SAVE_UNIT",
                    state: TaskStates.PROCESSING,
                    apiMethod: "post",
                    error: ""
                }]
        }
    };*/

    constructor() {
        //load task queue from storage
        // if there exists stored session data ,we will load it first
        let taskQueue = TaskHelper.taskQueueData;
        if (taskQueue) {
            _.each(taskQueue, (item) => {
                if (item) {
                    item.isProcessing = false;
                }
            });
            this.taskQueueData = taskQueue;
        } else {
            this.taskQueueData = {}
        }
    }

    startProcessing = () => {
        DebugLogger.log("BackgroundService: Started");
        this.timer = setInterval(() => {
            this.processTask();
        }, taskRoundSec * 1000);
    };

    stopProcessing = () => {
        DebugLogger.log("BackgroundService: Stopped");
        if (this.timer) {
            clearInterval(this.timer);
        }

        //force set all isProcessing to false, since we are open app again in this case
        if (this.taskQueueData) {
            _.each(this.taskQueueData, (item) => {
                if (item) {
                    item.isProcessing = false;
                }
            });

            //save the isProcessing state
            TaskHelper.saveTaskIntoStorage(this.taskQueueData);
        }
    };

    /**
     * Process task one by one, if one fails we sleep and wait next round
     * when one success, pop it in queue and update storage
     * if last process is not completed, ignore and wait next round
     */
    processTask = () => {
        //DebugLogger.log("BackgroundService: Check");
        if (this.taskQueueData) {
            //if you are not login we do nothing
            let store = StoreManager.getStore();
            let authorization = store.getState().session.authorization;
            if (authorization) {
                _.each(this.taskQueueData, (item, key) => {
                    if (_.isPlainObject(item) && _.has(item, "queue") && item.queue.length > 0 && !item.isProcessing) {
                        item.isProcessing = true;
                        DebugLogger.log(`BackgroundService: Background task  ${key} count ${item.queue.length}`);
                        this.processTaskOneByOne(key, item.queue)
                            .then(() => {
                                item.isProcessing = false;

                                //save the isProcessing state
                                TaskHelper.saveTaskIntoStorage(this.taskQueueData);

                                DebugLogger.log(`BackgroundService: ${key} Task Process Done`);
                            })
                            .catch(error => {
                                item.isProcessing = false;

                                //save the isProcessing state
                                TaskHelper.saveTaskIntoStorage(this.taskQueueData);

                                DebugLogger.log(`BackgroundService: ${key} Process error`);
                                DebugLogger.log(error);
                            });
                    }
                    else {
                        //DebugLogger.log("BackgroundService: No pending Task");
                    }
                });
            } else {
                DebugLogger.log("BackgroundService: Member not login, Quit");
            }
        } else {
            //DebugLogger.log("BackgroundService: No task here");
        }
    };


    //process the first task in task queue, if success we shift it and save into storage
    processFirstTask = (key, queue) => {
        if (_.isArray(queue) && queue.length > 0) {
            let task = queue[0];
            if (_.isPlainObject(task) && task.state === TaskStates.PENDING) {
                return new Promise((resolve, reject) => {
                    this.processApiCallTask(task)
                        .then(() => {
                            queue.shift();

                            this.saveQueue(key, queue);
                            return resolve();
                        })
                        .catch(err => {
                            //save error
                            queue[0].error = err.message;
                            queue[0].state = TaskStates.FAILED;
                            //save into queue
                            const errTask = queue.shift();
                            queue.push(errTask);
                            this.saveQueue(key, queue);
                            return reject(err);
                        });
                });
            } else {
                const errTask = queue.shift();
                queue.push(errTask);
                this.saveQueue(key, queue);
                return Promise.reject();
            }
        } else {
            return Promise.reject();
        }
    };

    //return a promise?
    processApiCallTask = item => {
        if (item.data && item.type) {
            return TaskHelper.processTask(item);
        } else {
            return Promise.resolve(null);
        }
    };

    saveQueue = (key, queue) => {
        if (key && _.has(this.taskQueueData, key)) {
            this.taskQueueData[key].queue = queue;
            TaskHelper.saveTaskIntoStorage(this.taskQueueData);
        }
    };

    processTaskOneByOne = (key, queue) => {
        let processIndex = 0;
        let runner = () => {
            return new Promise((resolve, reject) => {
                if (_.isArray(queue) && queue.length > 0) {
                    DebugLogger.log(`BackgroundService: start process ${key} task ${processIndex}`);
                    this.processFirstTask(key, queue)
                        .then(() => {
                            DebugLogger.log(`BackgroundService: process ${key} task ${processIndex} done`);
                            processIndex++;
                            resolve(runner()); // Pass another promise into resolve(), the passed promise will also be resolve()
                        })
                        .catch(err => {
                            DebugLogger.log(`BackgroundService: process ${key} task ${processIndex} error`);
                            DebugLogger.log(err);
                            reject(err);
                        });
                } else {
                    return resolve(`BackgroundService: Task ${key} Process Done`);
                }
            });
        };
        return runner();
    };



}

export default new BackgroundTaskService();
