//import * as Helper from "../../../../utils/Helper";
import ApiService from "../../../ApiService";

const EditService = {
    loadScreenView: (id, successCallback, failureCallback) => {
        ApiService.post("/web/admin/unit/edit/loadscreenview", { id }, successCallback, failureCallback);
    },
    saveUnit: (vo, successCallback, failureCallback) => {
        ApiService.post("/web/admin/unit/edit/save", vo, successCallback, failureCallback);
    },
    batchAddUnit: (vo, successCallback, failureCallback) => {
        ApiService.post("/web/admin/unit/edit/batchadd", vo, successCallback, failureCallback);
    },
};

export default EditService;