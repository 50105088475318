/*
 * App Theme - Colors
 *
 * React Native Starter App
 * https://github.com/mcnamee/react-native-starter-app
 */

import UserStyle from "./userStyle";
import color from "color";
import Constants from "../config/Constants";

export default {
    background: "#f4f4f4",
    cardBackground: "#FFFFFF",
    listItemBackground: "#FFFFFF",
    brand: {
        get primary() {
            return UserStyle.primary;
        },

        get secondary() {
            return UserStyle.secondary;
        },
        get primaryLight() {
            return color(UserStyle.primary).lighten(0.2).hex();
        },
        get secondaryLight() {
            return color(UserStyle.secondary).lighten(0.2).hex();
        },

        info: "#19589b",
        success: "#5cb85c",
        danger: "#d9534f",
        warning: "#eea236",
        note: "#f5c24f",

        // web colors
        webBlue:"rgb(97, 169, 220)",
        get webBlueLight(){
            return color(this.webBlue).lighten(0.5).hex();
        },

        webWin:"rgb(76, 173, 76)",
        get webWinLight(){
            return color(this.webWin).lighten(0.5).hex();
        },

        webLoss:"rgb(217, 83, 79)",
        get webLossLight(){
            return color(this.webLoss).lighten(0.5).hex();
        },

        webTies:"rgb(150, 150, 150)",
        get webTiesLight(){
            return color(this.webTies).lighten(0.5).hex();
        },

        webWarning:"rgb(238, 162, 54)",
        get webWarningLight(){
            return color(this.webWaitList).lighten(0.5).hex();
        },
    },
    textPrimary: "#222222",
    textSecondary: "#777777",
    headingPrimary: Constants.PRIMARY_COLOR,
    headingSecondary: Constants.SECONDARY_COLOR,

    textGrey: "#EAEAEA",
    textGreyDark: "#cccccc",

    textWhite: "#ffffff",
    textWhite75: "rgba(255,255,255,.75)",
    textWhite54: "rgba(255,255,255,.54)",
    border: "#D0D1D5",

    noticeBg: "#FFF5E4",
    noticeBorder: "#FFECCB",

    badgeInfo: "#3F57D3",
    badgeWarning: "#f0ad4e",    

    bgGreyDark: "#4A4A4A",
    bgGreyLight: "#fafafa",

    facebook: "#185AA4",
    twitter: "#13CCF8",

    tabbar: {
        background: "#ffffff",
        iconDefault: "#BABDC2",
        iconSelected: Constants.PRIMARY_COLOR,
    }
};
