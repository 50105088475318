import React from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import "./i18n.js";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import HttpsRedirect from "react-https-redirect";

import ErrorBoundary from "./components/base/ErrorBoundary";


// Initialize toast notification
toast.configure();

// const ErrorView = () =>
//   <div>
//     <p>Inform users of an error in the component tree.</p>
//   </div>;

ReactDOM.render(
    <HttpsRedirect disabled={!window.g_settings.isSupportHTTPS}>
        <ErrorBoundary
            //fallback={ErrorView}
        >
            <App />
        </ErrorBoundary>
    </HttpsRedirect>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
