import { createReducer } from "reduxsauce";

import ReducerRegister from "../ReducerRegister";
import DefaultService from "../../services/screens/DefaultService";
import { ActionTypes } from "../actionTypes/screens/DefaultActionTypes";
import AsyncActionReducers from "../AsyncActionReducers";

import UIDs from "../../UIDs";

const uid = UIDs.screens.default;

//
// Actions
//
const Actions = {
    loadAppAsync: () => {
        return (dispatch /*, getState*/) => {
            dispatch({ type: ActionTypes.LOAD_APP_ASYNC });

            DefaultService.loadApp(
                response => {
                    dispatch(Actions.loadAppSuccess(response));
                },
                error => {
                    dispatch(Actions.loadAppFailure(error));
                }
            );
        };
    },

    loadAppSuccess: data => ({
        type: ActionTypes.LOAD_APP_SUCCESS,
        parentType: ActionTypes.LOAD_APP_ASYNC,
        data
    }),

    loadAppFailure: error => ({
        type: ActionTypes.LOAD_APP_FAILURE,
        parentType: ActionTypes.LOAD_APP_ASYNC,
        error
    }),
};

//
// Initial State
//
const INITIAL_STATE = {

};

//
// Reducers
//

// Use reduxsauce's createReducer to make it clearer for
// each action handler
const mergedReducer = createReducer(INITIAL_STATE, {
    [ActionTypes.LOAD_APP_ASYNC]: AsyncActionReducers.actionReducer,
    [ActionTypes.LOAD_APP_SUCCESS]: AsyncActionReducers.successReducer,
    [ActionTypes.LOAD_APP_FAILURE]: AsyncActionReducers.failureReducer
});

// Enable dynamically register and unregister reducer
const reducer = new ReducerRegister(uid, mergedReducer);

export { ActionTypes, Actions, reducer, INITIAL_STATE };