import DebugLogger from "../utils/DebugLogger";

const AsyncActionReducers = {

    actionReducer: (state, action) => {
        var executedActions = { ...state.executedActions };
        executedActions[action.type] = { ...action, isExecuting: true, isDone: false, error: null, postReducer: action.postReducer };

        var newState = { ...state, executedActions: executedActions, action: executedActions[action.type] };
        if (executedActions[action.type].postReducer) {
            return executedActions[action.type].postReducer(newState, action);
        } else {
            return newState;
        }
    },

    successReducer: (state, action) => {
        if (!action.parentType) {
            DebugLogger.throw("action.parentType should be not null or undefined. Please check your settings for your async action.");
        }

        var executedActions = { ...state.executedActions };
        executedActions[action.parentType] = { ...state.executedActions[action.parentType], isExecuting: false, isDone: true, data: action.data, postReducer: action.postReducer };

        var newState = { ...state, executedActions: executedActions, action: executedActions[action.parentType] };
        if (executedActions[action.parentType].postReducer) {
            return executedActions[action.parentType].postReducer(newState, action);
        } else {
            return newState;
        }
    },

    failureReducer: (state, action) => {
        if (!action.parentType) {
            DebugLogger.throw("action.parentType should be not null or undefined. Please check your settings for your async action.");
        }

        var executedActions = { ...state.executedActions };
        executedActions[action.parentType] = { ...state.executedActions[action.parentType], isExecuting: false, error: action.error, postReducer: action.postReducer };

        var newState = { ...state, executedActions: executedActions, action: executedActions[action.parentType] };
        if (executedActions[action.parentType].postReducer) {
            return executedActions[action.parentType].postReducer(newState, action);
        } else {
            return newState;
        }
    }
};

export default AsyncActionReducers;