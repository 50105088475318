import UIDs from "../../../UIDs";

const uid = UIDs.screens.default;

const ActionTypes = {
    LOAD_APP_ASYNC: `${uid}.loadAppAsync`,
    LOAD_APP_SUCCESS: `${uid}.loadAppSuccess`,
    LOAD_APP_FAILURE: `${uid}.loadAppFailure`    
};

export { ActionTypes };
