import React from "react";
// import { Crashlytics } from "react-native-fabric";

// import Analytics from "../utils/Analytics";

class BaseView extends React.Component {

    get viewName() {
        throw new Error("Not implemented.");
    }

    componentDidMount() {
        // // track screen view here
        // var userId = this.props.userId ? this.props.userId : "(empty)";
        // // Analytics.trackScreenView(userId, this.viewName ? this.viewName : "(empty)");
        // //set user id
        // Crashlytics.setUserIdentifier(userId);
    }
}

export default BaseView;