import moment from "moment";

import DebugLogger from "./DebugLogger";

const createStorageEngine = key => ({
    load() {
        const jsonState = localStorage.getItem(key);
        return JSON.parse(jsonState) || {};
    },

    save(state) {
        const jsonState = JSON.stringify(state);
        return localStorage.setItem(key, jsonState);
    },
    delete() {
        return localStorage.removeItem(key);
    }
});

const StorageEngine = key => ({
    load() {
        const now = moment().format("hh:mm:ss:SSS");
        const groutTitle = `load from stroage, key = ${key} @ ${now} `;
        const res = createStorageEngine(key).load();
        DebugLogger.groupMessage(groutTitle, res);
        return res;
    },
    save(state) {
        const now = moment().format("h:mm:ss:SSS");
        const groutTitle = `save into stroage, key = ${key} @ ${now} `;
        DebugLogger.groupMessage(groutTitle, state);

        return createStorageEngine(key).save(state);
    },
    delete() {
        const now = moment().format("h:mm:ss:SSS");
        const groutTitle = `delete in stroage, key = ${key} @ ${now}`;
        DebugLogger.groupMessage(groutTitle, null);

        return createStorageEngine(key).delete();
    }
});


export default StorageEngine;
