import UIDs from "../../../../UIDs";

const uid = UIDs.screens.admin.profile;

const ActionTypes = {
    LOAD_SCREEN_VIEW_ASYNC: `${uid}.loadScreenViewAsync`,
    LOAD_SCREEN_VIEW_SUCCESS: `${uid}.loadScreenViewSuccess`,
    LOAD_SCREEN_VIEW_FAILURE: `${uid}.loadScreenViewFailure`,
    SAVE_USER_ASYNC: `${uid}.saveUserAsync`,
    SAVE_USER_SUCCESS: `${uid}.saveUserSuccess`,
    SAVE_USER_FAILURE: `${uid}.saveUserFailure`,
    SAVE_PASSWORD_ASYNC: `${uid}.savePasswordAsync`,
    SAVE_PASSWORD_SUCCESS: `${uid}.savePasswordSuccess`,
    SAVE_PASSWORD_FAILURE: `${uid}.savePasswordFailure`,
    UPLOAD_PHOTO_ASYNC: `${uid}.uploadPhotoAsync`,
    UPLOAD_PHOTO_SUCCESS: `${uid}.uploadPhotoSuccess`,
    UPLOAD_PHOTO_FAILURE: `${uid}.uploadPhotoFailure`,
    SHOW_COUNT_DOWN: `${uid}.showCountDown`,
    GET_ALL_COUNTRY_CODE_ASYNC:`${uid}.getAllCountryCodeAsync`,
    GET_ALL_COUNTRY_CODE_SUCCESS:`${uid}.getAllCountryCodeSuccess`,
    GET_ALL_COUNTRY_CODE_FAILURE:`${uid}.getAllCountryCodeFailure`,
    SEND_SMS_VERIFY_CODE_ASYNC:`${uid}.sendSmsVerifyCodeAsync`,
    SEND_SMS_VERIFY_CODE_SUCCESS:`${uid}.sendSmsVerifyCodeSuccess`,
    SEND_SMS_VERIFY_CODE_FAILURE:`${uid}.sendSmsVerifyCodeFailure`,
    SEND_EMAIL_VERIFY_CODE_ASYNC:`${uid}.sendEmailVerifyCodeAsync`,
    SEND_EMAIL_VERIFY_CODE_SUCCESS:`${uid}.sendEmailVerifyCodeSuccess`,
    SEND_EMAIL_VERIFY_CODE_FAILURE:`${uid}.sendEmailVerifyCodeFailure`,
};

export { ActionTypes };