// import { GoogleAnalyticsTracker } from "react-native-google-analytics-bridge";
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAqgZddmuVFBD69ycy7R9iwRj5zsLfenuY",
    authDomain: "aichinese-295911.firebaseapp.com",
    projectId: "aichinese-295911",
    storageBucket: "aichinese-295911.appspot.com",
    messagingSenderId: "711266234345",
    appId: "1:711266234345:web:f71263139d784ffab32578",
    measurementId: "G-L4VCPVW6J4",
};

class Analytics {
    constructor() {
        this._tracker = null;
        this.callHelper = null;
        // Initialize Firebase
        // NOTE: 不初始化firebase, 否则会导致页面加载完成时间超过2分钟, WebScreenshot失败
        const nofirebase = window.location.search && window.location.search.indexOf("nofirebase") >= 0;
        if (!nofirebase) {
            //lazy import firebase lib
            import("firebase/app").then(({ initializeApp }) => {
                const app = initializeApp(firebaseConfig);
                import("firebase/analytics").then(({ getAnalytics, logEvent, setUserId, setUserProperties }) => {
                    this._analytics = getAnalytics(app);
                    this.callHelper = { logEvent, setUserId, setUserProperties };
                });
            });
        }
    }

    initialize() {
        // this._tracker = new GoogleAnalyticsTracker("UA-124203308-3");
    }

    isSdkReady() {
        return this._analytics && this.callHelper;
    }

    trackEvent(userId, screenName, event) {
        // if (this._tracker) {
        //     this._tracker.setUser(userId);
        //     this._tracker.trackEvent(screenName, event);
        // }
        if (!this.isSdkReady()) {
            return;
        }

        this.callHelper.setUserId(this._analytics, userId);
        this.callHelper.logEvent(this._analytics, event, {
            firebase_screen: screenName,
            firebase_screen_class: screenName,
        });
    }

    trackScreenView(userId, screenName, userProps, pageProps) {
        if (!this.isSdkReady()) {
            return;
        }

        this.callHelper.setUserId(this._analytics, userId);
        this.callHelper.setUserProperties(this._analytics, { user_role: userProps.userRole, user_partner: userProps.userPartner });
        this.callHelper.logEvent(this._analytics, "screen_view", {
            firebase_screen: screenName,
            firebase_screen_class: screenName,
            page_partner: pageProps.pagePartner,
        });
    }
}

export default new Analytics();
