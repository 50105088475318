import UIDs from "../../UIDs";

const uid = UIDs.taskqueue;

const ActionTypes = {
    LOAD_TASK: `${uid}.loadTask`,
    ADD_TASK: `${uid}.addTask`,
    DELETE_TASK: `${uid}.deleteTask`,
    UPDATE_TASK: `${uid}.updateTask`,
    BATCH_ADD_TASK: `${uid}.processTask`,
    EXECUTE_TASK_ASYNC:`${uid}.executeTaskAsync`,
    EXECUTE_TASK_SUCCESS:`${uid}.executeTaskSuccess`,
    EXECUTE_TASK_FAILURE:`${uid}.executeTaskFailure`,
};

export { ActionTypes };
