import Constants from "../config/Constants";

class userStyle {
    internalColorObj = {
        primaryColor: Constants.PRIMARY_COLOR,
        secondaryColor: Constants.SECONDARY_COLOR
    };

    constructor() {
    }

    get primary() {
        return this.internalColorObj.primaryColor;
    }

    set primary(newColor) {
        this.internalColorObj.primaryColor = newColor;
    }

    get secondary() {
        return this.internalColorObj.secondaryColor;
    }

    set secondary(newColor) {
        this.internalColorObj.secondaryColor = newColor;
    }
}

const UserStyle = new userStyle();

export default UserStyle;
