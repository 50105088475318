import _ from "lodash";
import StoreManager from "../redux/";
import StorageEngine from "./StorageEngine";
import UnitEditService from "../services/screens/admin/unit/EditService"
import { Actions } from "../redux/TaskRedux"


const TASKQUEUE_STORAGE_KEY = "TASKQUEUE_STORAGE_KEY";

const TaskTypes = {
    SAVE_UNIT: "SAVE_UNIT",
};

const TaskStates = {
    PENDING: "pending",
    PROCESSING: "processing",
    FAILED: "failed",
};


const executeSaveUnitTaskAsync = (task) => {
    const dispatch = StoreManager.getStore().dispatch;

    return new Promise((resolve, reject) => {
        dispatch(Actions.executeTaskAsync(task));

        UnitEditService.saveUnit(task.data,
            response => {
                dispatch(Actions.executeTaskSuccess(task, response));
                resolve(response);
            },
            error => {
                dispatch(Actions.executeTaskFailure(task, error));
                reject(error);
            })
    });
}

class TaskHelper {
    constructor(taskType) {
        //load task queue from storage
        let taskQueue = StorageEngine(TASKQUEUE_STORAGE_KEY).load();
        if (taskQueue) {
            _.each(this.taskQueue, (item) => {
                if (item) {
                    item.isProcessing = false;
                }
            });
            this.taskQueueData = taskQueue;
        } else {
            this.taskQueueData = {}
        }
    }


    saveTaskIntoStorage = taskQueue => {
        //save into storage
        StorageEngine(TASKQUEUE_STORAGE_KEY).save(taskQueue);
    };

    /*task = {
        id: "",
        data: "",
        type: TaskTypes.SAVE_UNIT,
        state: TaskStates.PENDING,
        apiMethod: "post",
        error: ""
    }*/
    appendTask = task => {
        if (task) {
            if (_.has(task, "type")) {
                let taskType = task.type;
                if (_.has(this.taskQueueData, taskType)) {
                    this.taskQueueData[taskType].queue.push(task);
                } else {
                    this.taskQueueData[taskType] = {
                        isProcessing: false,
                        queue: [task]
                    };
                }

                this.saveTaskIntoStorage(this.taskQueueData);
            }
        }
    };

    updateTask = (id, taskType, error) => {
        if (this.taskQueueData && this.taskQueueData[taskType]) {
            let targetQueue = _.cloneDeep(this.taskQueueData[taskType].queue);
            let taskIndex = targetQueue.findIndex(t => t.id === id);
            let newTask = _.cloneDeep(targetQueue[taskIndex]);
            newTask.error = error.message;
            newTask.state = TaskStates.FAILED;
            targetQueue.splice(taskIndex, 1, newTask);
            this.taskQueueData[taskType].queue = targetQueue;
            this.saveTaskIntoStorage(this.taskQueueData);
        }
    };

    removeTaskById = (id, taskType) => {
        if (this.taskQueueData && this.taskQueueData[taskType]) {
            let targetQueue = _.cloneDeep(this.taskQueueData[taskType].queue);
            targetQueue.findIndex(t => t.id === id);
            targetQueue.splice(targetQueue.findIndex(t => t.id === id), 1);
            this.taskQueueData[taskType].queue = targetQueue;
            this.saveTaskIntoStorage(this.taskQueueData);
        }
    };

    processTask = (task) => {
        switch (task.type) {
            case TaskTypes.SAVE_UNIT:
                return executeSaveUnitTaskAsync(task);

            default:
                break;
        }
    }

    getAllTask = () => {
        return StorageEngine(TASKQUEUE_STORAGE_KEY).load() || {};
    }

    clearAllTask = () => {
        this.taskQueueData = {};
        this.saveTaskIntoStorage(this.taskQueueData);
    };

    getPendingTaskByQueueType = queueType => {
        if (queueType && _.has(this.taskQueueData, queueType)) {
            if (this.taskQueueData[queueType] && this.taskQueueData[queueType].queue) {
                return _.cloneDeep(this.taskQueueData[queueType].queue);
            } else {
                return [];
            }
        } else {
            return [];
        }
    };

    isTaskQueueRunning = (taskType) => {
        if (taskType && _.has(this.taskQueueData, taskType)) {
            return this.taskQueueData[taskType].isProcessing;
        }
        return false;
    };

    getCurrentExecutingData = () => {
        return _.cloneDeep(this.taskQueueData);
    };
}

export default new TaskHelper();
export { TaskTypes, TaskStates, TASKQUEUE_STORAGE_KEY }