import ApiService from "../ApiService";
import AppConfig from "../../config/AppConfig";
import DebugLogger from "../../utils/DebugLogger";
import * as Helper from "../../utils/Helper";
import RegionServerManager from "../../mobile/utils/RegionServerManager";

const DefaultService = {
    loadApp: (successCallback, failureCallback) => {
        RegionServerManager.instance().loadLocalStore().then(() => {
            ApiService.post(
                "/app/load",
                {},
                response => {
                    let snakeCaseObject = Helper.convertCamelCaseKeysToUpperSnakeCase(response);
                    //update app config
                    DebugLogger.groupMessage("Get New App Config From Server", snakeCaseObject);
                    AppConfig.updateAppConfig(snakeCaseObject);
                    DebugLogger.groupMessage("Update App Config", AppConfig);

                    if (response.regionServers && response.regionServers.length > 0) {
                        RegionServerManager.instance().setServers(response.regionServers);
                    }
                    else {
                        // once app is loaded, then we want to update api service url since 
                        // it could be changed by the server based on the location of client
                        if (!Helper.isNullOrEmpty(response.apiEndPoint)) {
                            // update app service base with new api endpoint if passed to client
                            AppConfig.APP_SERVICE_BASE = response.apiEndPoint;

                            ApiService.setBaseURL(AppConfig.APP_SERVICE_BASE);
                        }

                        if (!Helper.isNullOrEmpty(response.fileServerEndPoint)) {
                            AppConfig.FILE_SERVER = response.fileServerEndPoint;
                        }
                    }

                    if (successCallback) successCallback(response);
                },
                error => {
                    RegionServerManager.instance().clearLocalStore();
                    if (failureCallback) failureCallback(error);
                },
            );
        });
    }
};

export default DefaultService;
