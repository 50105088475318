import _ from "lodash";
import Routes from "./Routes";
import NavigateActionTypes from "./NavigateActionTypes";
import Constants from "../config/Constants";

class NavigationService {
    constructor() {
        this._routes = Routes;

        // build a dictionary for fast processing
        this._layoutRoutesDict = {};
        this._routesDict = {};
        this._routesDictByUID = {};
        for (let i = 0; i < this._routes.length; i++) {
            let layout = this._routes[i].layout;
            if (!this._layoutRoutesDict[layout]) {
                this._layoutRoutesDict[layout] = [];
            }

            this._routesDict[layout + this._routes[i].path] = this._routes[i];
            this._routesDictByUID[this._routes[i].uid] = this._routes[i];
            this._layoutRoutesDict[layout].push(this._routes[i]);
        }
    }

    // fullpath = (layout + path) in the route object
    getRoute(fullpath) {
        return this._routesDict[fullpath];
    }

    getRouteByUID(uid) {
        return this._routesDictByUID[uid];
    }

    getRoutesByUID(uid) {
        var route = this._routesDictByUID[uid];
        return this.getRoutes(route.path);
    }

    getRoutes(layout) {
        var layoutRoutes = this._routes;
        if (layout) {
            layoutRoutes = this._layoutRoutesDict[layout] ? this._layoutRoutesDict[layout] : [];
        }

        return layoutRoutes;
    }

    // getRoutes(layout) {
    //     var layoutRoutes = this._routes;
    //     if (layout) {
    //         layoutRoutes = this._routesDict[layout] ? this._routesDict[layout] : [];
    //     }

    //     return layoutRoutes.map((prop, key) => {
    //         return (
    //             <Route
    //                 path={prop.layout + prop.path}
    //                 component={prop.component}
    //                 key={key}
    //             />
    //         );
    //     });
    // }

    // TODO: need to consider params
    // We will probably append params to the url
    navigateToPage(fromScreen, targetRouteName, params) {
        if (_.startsWith(targetRouteName, `${Constants.MOBILE_UID_PREFIX}.`)) {
            this.navigateToPageByUID(fromScreen, targetRouteName, params);
        } else {
            let history = fromScreen.props.history;
            if (!history) {
                history = fromScreen.props.navigationHistory;
            }
            history.push({
                pathname: targetRouteName,
                search: "",
                state: params,
            });
        }
    }

    navigateToPageByUID(fromScreen, targetUID, params) {
        var route = this.getRouteByUID(targetUID);
        var targetRouteName = route.layout + route.path;
        this.navigateToPage(fromScreen, targetRouteName, params);
    }

    navigateByAction(fromScreen, actionType, targetRouteName, params) {
        switch (actionType) {
            case NavigateActionTypes.BACK:
                fromScreen.props.history.goBack();
                break;
            // case NavigateActionTypes.RESET: {

            //     break;
            // }
            default:
                // do nothing
                break;
        }
    }

    getNavParams(props) {
        var state,
            params,
            search = {};

        if (!_.isUndefined(props) && _.has(props, "location")) {
            if (props.location.state) state = props.location.state;
            if (props.location.search) {
                const searchParams = new URLSearchParams(props.location.search.substring(1));
                for (let [k, v] of searchParams.entries()) {
                    search[k] = v;
                }
            }
        }

        if (!_.isUndefined(props) && _.has(props, "match")) {
            if (props.match.params) params = props.match.params;
        }

        return { ...state, ...params, ...search };
    }

    getPathNameByUID(targetUID) {
        var route = this.getRouteByUID(targetUID);
        var targetRouteName = route.layout + route.path;
        return targetRouteName;
    }
}

export default new NavigationService();
export { NavigationService as NavigationServiceClass };
