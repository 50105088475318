import React from "react";
import { Platform, StyleSheet } from "react-native";

var unitStyle = StyleSheet.create({
    //最外层的
    container: {
        flex: 1,
        padding: 15,
        color: "#333333",
        backgroundColor: "#ffffff",
    },

    //全部居中
    centerAlign: {
        justifyContent: "center",
        alignItems: "center",
    },

    //主轴居中
    centerMain: {
        justifyContent: "center",
    },

    //侧轴居中
    centerCross: {
        alignItems: "center",
    },

    //单个拼音大小
    fontSyllable: {
        fontSize: 60,
        lineHeight: 64,
        height: 64,
    },

    //单个拼音的喇叭
    iconAudioSyllable: {
        fontSize: 25,
        lineHeight: 29,
        height: 29,
        color: "#FFC000", //"#33CC66",
        paddingRight: 10,
    },

    //词的喇叭大小
    iconAudioWord: {
        fontSize: 27,
        lineHeight: 30,
        height: 30,
        color: "#FFC000", //"#33CC66",
        paddingRight: 10,
        marginTop: Platform.OS === "ios" ? -10 : 0,
    },

    //句子的喇叭大小
    iconAudioSentence: {
        fontSize: 27,
        lineHeight: 29,
        height: 29,
        color: "#FFC000", //"#33CC66",
        paddingRight: 10,
    },

    //只显示一个喇叭的大小
    iconAudioOnly: {
        fontSize: 50,
        lineHeight: 52,
        height: 52,
        color: "#FFC000", //"#33CC66",
        paddingRight: 10,
    },

    //只有一个词的字体大小
    onlyWordFontSize: {
        fontSize: 26,
        lineHeight: 29,
    },

    //录音的mic
    iconMic: {
        fontSize: 40,
        lineHeight: 42,
        height: 42,
        color: "#797979",
    },

    //排列
    flexDirectionRow: {
        flexDirection: "row",
    },

    //长的按钮选项的外框 D2/i8
    chooseView: {
        minHeight: 45,
        marginHorizontal: 0,
        borderRadius: 6,
        borderWidth: 1,
        borderColor: "#D1D1D1",
        marginBottom: 20,
        fontSize: 17,
        paddingLeft: 25,
        justifyContent: "center",
    },

    //中间显示的长的显示框 B4
    chooseViewCenter: {
        minHeight: 45,
        marginHorizontal: 0,
        borderRadius: 6,
        borderWidth: 1,
        borderColor: "#D1D1D1",
        marginBottom: 20,
        fontSize: 17,
        justifyContent: "center",
    },

    //长按钮选中后的外框
    chooseViewBorder: {
        borderColor: "#4F8EF7",
        borderWidth: 3,
    },
    flex1: {
        flex: 1,
    },
    flex2: {
        flex: 2,
    },
    flex3: {
        flex: 3,
    },
    title: {
        color: "#777",
    },
    baseTitle: {
        marginVertical: 4,
        fontSize: 17,
        color: "#777",
    },
    pdgb20: {
        paddingBottom: 20,
    },

    //默认的颜色
    itemDefColor: {
        color: "#242424",
    },

    itemSelColor: {
        //color: "#e1e7f5",
        color: "#E4E4E4",
    },

    //多选小块带拼音的
    blockItem: {
        minWidth: 40,
        minHeight: 36,
        marginRight: 20,
        marginTop: 10,
        marginBottom: 10,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 3,
        paddingLeft: 3,
        paddingRight: 3,
    },

    //多选小块不带拼音的
    blockItemNoPinyin: {
        minWidth: 40,
        height: 32,
        marginRight: 20,
        marginTop: 10,
        marginBottom: 10,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 3,
        paddingLeft: 3,
        paddingRight: 3,
    },

    //多选答案默认显示的样式
    blockAnswer: {
        backgroundColor: "#E4E4E4",
        borderWidth: 1,
        borderColor: "#E4E4E4",
    },

    //多字的样式
    blockAnswerInsertion: {
        borderWidth: 1,
        borderColor: "#FFB400",
    },
    blockAnswerInsertionFont: {
        color: "#FFB400",
    },

    //答案对的样式
    blockAnswerRight: {
        borderWidth: 2,
        borderColor: "green",
    },
    blockAnswerRightFont: {
        color: "green",
    },

    //答案错的样式
    blockAnswerWrong: {
        borderWidth: 2,
        borderColor: "red",
    },
    blockAnswerWrongFont: {
        color: "red",
    },

    //多选选项默认显示的样式
    blockSelect: {
        backgroundColor: "#ffffff",
        borderWidth: 1,
        borderColor: "#aeaeae",
    },

    blockSelected: {
        backgroundColor: "#ffffff",
        borderWidth: 1,
        borderColor: "#111",
    },

    blockSelectCurrent: {
        backgroundColor: "#ccffff",
        borderWidth: 1,
        borderColor: "#d6fcfd",
    },

    //其它只设置圆角的
    borRadius3: {
        borderRadius: 3,
    },

    //语音评测按钮样式
    soundBtn: {
        fontSize: 30,
        color: "#797979",
        paddingHorizontal: 25,
    },

    //新模板中新加的样式
    //默认的字体颜色，大小，行高
    defFont: {
        fontSize: 17,
        color: "#242424",
        letterSpacing: 0.1,
        lineHeight: 23,
    },

    fontSize20: {
        fontSize: 20,
        lineHeight: 22,
    },
    fontSize23: {
        fontSize: 23,
        lineHeight: 26,
    },
    fontSize32: {
        fontSize: 32,
        lineHeight: 36,
    },

    //Translation的大小
    //poab1 psab4
    defTranslationFont: {
        fontSize: 13,
        color: "#242424",
        letterSpacing: 0.1,
        lineHeight: 26,
    },

    defFontPoea7: {
        fontSize: 17,
        color: "#242424",
        letterSpacing: 0.1,
        lineHeight: 18,
    },

    defTranslationFontPoea7: {
        fontSize: 13,
        color: "#242424",
        letterSpacing: 0.1,
        lineHeight: 14,
    },

    defBtnFontSize: {
        fontSize: 17,
    },

    marginBottom20: {
        marginBottom: 20,
    },

    marginBottom30: {
        marginBottom: 30,
    },

    marginVertical20: {
        marginVertical: 20,
    },

    //父类子类的分隔线
    dividingLine: {
        height: 1,
        backgroundColor: "#B1B1B1",
        marginVertical: 6,
    },

    //父类中喇叭的大小，间距，颜色
    //包含喇叭的view间距
    fatherUnitAudioView: {
        marginVertical: 20,
    },

    //喇叭的大小，颜色
    fatherUnitAudio: {
        fontSize: 60,
        color: "#FFB400",
    },

    //限制上面部分的高度
    topHeight: {
        maxHeight: 150,
    },

    //一张图
    onlyImageSize: {
        width: 180,
        height: 120,
    },

    //多张图
    imagesSize: {
        width: 80,
        height: 80,
    },

    //对话
    Avatar: {
        color: "#333",
        width: 36,
        height: 36,
        borderRadius: 36,
        backgroundColor: "#f2f2f2",
        alignItems: "center",
        justifyContent: "center",
    },
    ContainerStyle: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 5,
        //minWidth: 100,
        maxWidth: 240,
        overflow: "hidden",
    },
    LeftConversation: {
        backgroundColor: "#f2f2f2",
        alignSelf: "flex-start",
    },
    CenterConversation: {
        backgroundColor: "#f2f2f2",
        alignSelf: "center",
    },
    RightConversation: {
        backgroundColor: "#f2f2f2",
        alignSelf: "flex-end",
    },

    singleUnit: {},

    overlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100%",
        zIndex: 999,
    },
    speechBoxContainer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    flexRowWrap: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    choiceWord: {
        paddingHorizontal: 1,
        paddingVertical: 0,
    },

    //继续和提交按钮样式
    btnSubmit: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#1E90FF",
        width: "90%",
        height: 40,
        borderRadius: 30,
    },

    btnSubmitAutoWidth: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#1E90FF",
        height: 40,
        borderRadius: 30,
        paddingLeft:10,
        paddingRight:10,
    },

    btnSubmitDisabled: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#999999",
        width: "90%",
        height: 40,
        borderRadius: 30,
    },
});

export default unitStyle;
