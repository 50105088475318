import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";

import BaseScreen from "../components/base/BaseScreen";

import "../assets/vendor/nucleo/css/nucleo.css";
import "../assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "../assets/scss/aichinese.scss";

import "../assets/scss/screens/default.scss";
//
// import AdminLayout from "../layouts/AdminLayout";
// import AccountLayout from "../layouts/AccountLayout";
//import PortalLayout from "../layouts/PortalLayout";
// import PlainLayout from "../layouts/PlainLayout";
// import MobileLayout from "../layouts/MobileLayout";
// import MainLayout from "../layouts/MainLayout";
// import RoomLayout from "../layouts/RoomLayout";

import { NavigationService } from "../navigation";

import { ActionTypes } from "../redux/actionTypes/screens/DefaultActionTypes";
import { Actions, reducer, INITIAL_STATE } from "../redux/screens/DefaultRedux";
import { Actions as SessionActions } from "../redux/SessionRedux";
import { Actions as TaskActions } from "../redux/TaskRedux";
import { withTranslation } from "react-i18next";
import UIDs from "../UIDs";
import MobileUIDs from "../mobile/UIDs";
import Constants from "../config/Constants";
const uid = UIDs.screens.default;

const AdminLayout = React.lazy(() => import("../layouts/AdminLayout"));
const AccountLayout = React.lazy(() => import("../layouts/AccountLayout"));
const PortalLayout = React.lazy(() => import("../layouts/PortalLayout"));
const PlainLayout = React.lazy(() => import("../layouts/PlainLayout"));
const MobileLayout = React.lazy(() => import("../layouts/MobileLayout"));
// const MainLayout = React.lazy(() => import("../layouts/MainLayout"));
const RoomLayout = React.lazy(() => import("../layouts/RoomLayout"));

class DefaultScreen extends BaseScreen {
    get routeName() {
        return uid;
    }

    get reducer() {
        return reducer;
    }

    get actionHandlers() {
        return {
            [ActionTypes.LOAD_APP_ASYNC]: { done: this._handleLoadAppAsyncDone, error: null },
        };
    }

    // constructor(props) {
    //   super(props);

    //   // create store when app starts
    //   // this._store = StoreManager.getStore();
    // }

    componentDidMount() {
        super.componentDidMount();

        document.title = this.props.t("yidu");

        // load session from local storage if any
        this.props.tryLoadSession();

        // load necessary data to initialize the app
        this.props.loadAppAsync();

        //load task from local storage
        this.props.loadTask();
    }

    render() {
        if (!this.isActionDone(ActionTypes.LOAD_APP_ASYNC)) {
            return <div className="loader"></div>;
        } else {
            return (
                <Suspense fallback={<div className="loader"></div>}>
                    <Switch>
                        <Route path="/admin" component={AdminLayout} />
                        <Route path="/account" component={AccountLayout} />
                        <Route path="/portal" component={PortalLayout} />
                        <Route path="/plain" component={PlainLayout} />
                        <Route path="/room" component={RoomLayout} />
                        {/*<Route path="/main" component={MainLayout} />*/}
                        <Route path="/mobile" component={MobileLayout} />
                    </Switch>
                </Suspense>
            );
        }
    }

    _handleLoadAppAsyncDone = () => {
        if (_.startsWith(this.props.location.pathname, "/plain/") || _.startsWith(this.props.location.pathname, "/room/")) {
        } else if (
            !this.isLoggedIn(this.props.userId) &&
            !_.startsWith(this.props.location.pathname, "/mobile/") &&
            !_.startsWith(this.props.location.pathname, "/account/") &&
            !_.startsWith(this.props.location.pathname, "/portal/")
        ) {
            NavigationService.navigateToPageByUID(this, UIDs.screens.portal.home);
        } else if (this.props.location.pathname === "/") {
            if (this.isOnCall) {
                NavigationService.navigateToPageByUID(this, UIDs.screens.admin.error.dashboard);
            } else if (this.isAgent) {
                NavigationService.navigateToPageByUID(this, UIDs.screens.admin.agent.agentreferralcode);
            } else if (this.isStudent) {
                NavigationService.navigateToPageByUID(this, MobileUIDs.screens.homework.newdefault, null);
            } else {
                NavigationService.navigateToPageByUID(this, UIDs.screens.admin.oralbook.list);
            }
        }
    };
}

const mapStateToProps = (state) => {
    return {
        localState: state.screens[reducer.key] ? state.screens[reducer.key] : INITIAL_STATE,
        userId: state.session.userId,
        user: state.session.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadAppAsync: () => dispatch(Actions.loadAppAsync()),
        tryLoadSession: () => dispatch(SessionActions.tryLoadSession()),
        loadTask: () => dispatch(TaskActions.loadTask()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(uid)(DefaultScreen));
