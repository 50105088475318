import { createReducer } from "reduxsauce";

import { ActionTypes } from "./actionTypes/TaskActionTypes"

import { generateUUID, isNullOrEmpty } from "../utils/Helper"
import TaskHelper, { TaskTypes, TaskStates } from "../utils/TaskHelper"
import AsyncActionReducers from "./AsyncActionReducers";



/* ------------- Actions ------------- */
const Actions = {
    loadTask: () => ({
        type: ActionTypes.LOAD_TASK,
    }),

    addTask: (data, taskType) => ({
        type: ActionTypes.ADD_TASK,
        data,
        taskType
    }),

    deleteTask: (id, taskType) => ({
        type: ActionTypes.DELETE_TASK,
        id,
        taskType
    }),

    updateTask: (id, taskType, error) => ({
        type: ActionTypes.UPDATE_TASK,
        id,
        taskType,
        error
    }),

    batchAddTask: (taskList, taskType) => ({
        type: ActionTypes.BATCH_ADD_TASK,
        taskList,
        taskType
    }),

    executeTaskAsync: task => ({
        type: ActionTypes.EXECUTE_TASK_ASYNC,
        task
    }),

    executeTaskSuccess: (task, response) => ({
        type: ActionTypes.EXECUTE_TASK_SUCCESS,
        parentType: ActionTypes.EXECUTE_TASK_ASYNC,
        postReducer: executeTaskSuccessReducer,
        task,
        response
    }),

    executeTaskFailure: (task, error) => ({
        type: ActionTypes.EXECUTE_TASK_FAILURE,
        parentType: ActionTypes.EXECUTE_TASK_ASYNC,
        postReducer: executeTaskFailureReducer,
        task,
        error
    }),
};

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
    taskQueue: { [TaskTypes.SAVE_UNIT]: { isProcessing: false, queue: [] } }
};

/* ------------- Reducers ------------- */
const loadTaskReducer = (state) => {
    let taskQueue = TaskHelper.getAllTask();
    if (isNullOrEmpty(taskQueue)) taskQueue = state.taskQueue;
    return { ...state, taskQueue: taskQueue }
};

const addTaskReducer = (state, { data, taskType }) => {
    const id = generateUUID();
    const task = { id, type: taskType, data: data, state: TaskStates.PENDING }
    TaskHelper.appendTask(task);
    let taskQueue = TaskHelper.getAllTask();
    if (isNullOrEmpty(taskQueue)) taskQueue = state.taskQueue;
    return { ...state, taskQueue: taskQueue }
};

const updateTaskReducer = (state, { id, taskType, error }) => {
    TaskHelper.updateTask(id, taskType, error);
    let taskQueue = TaskHelper.getAllTask();
    if (isNullOrEmpty(taskQueue)) taskQueue = state.taskQueue;
    return { ...state, taskQueue: taskQueue }
};

const deleteTaskReducer = (state, { id, taskType }) => {
    TaskHelper.removeTaskById(id, taskType);
    let taskQueue = TaskHelper.getAllTask();
    if (isNullOrEmpty(taskQueue)) taskQueue = state.taskQueue;
    return { ...state, taskQueue: taskQueue }
};

const batchAddTaskReducer = (state, { taskList, taskType }) => {
    for (let data of taskList.data) {
        const id = generateUUID();
        const taskData = {
            data,
            dataType: taskList.dataType,
            duration: taskList.duration,
            ownerId: taskList.ownerId,
            ownerType: taskList.ownerType,
            unitType: taskList.unitType,
            resourceId: generateUUID()
        };
        const task = { id, type: taskType, data: taskData, state: TaskStates.PENDING }
        TaskHelper.appendTask(task);
    }
    let taskQueue = TaskHelper.getAllTask();
    if (isNullOrEmpty(taskQueue)) taskQueue = state.taskQueue;
    return { ...state, taskQueue: taskQueue }
};


const executeTaskSuccessReducer = (state, { task }) => {
    TaskHelper.removeTaskById(task.id, task.type);
    let taskQueue = TaskHelper.getAllTask();
    if (isNullOrEmpty(taskQueue)) taskQueue = state.taskQueue;
    return { ...state, taskQueue: taskQueue }
}

const executeTaskFailureReducer = (state, { task, error }) => {
    TaskHelper.updateTask(task.id, task.type, error);
    let taskQueue = TaskHelper.getAllTask();
    if (isNullOrEmpty(taskQueue)) taskQueue = state.taskQueue;
    return { ...state, taskQueue: taskQueue }
}


/* ------------- Hookup Reducers To Types ------------- */

const reducer = createReducer(INITIAL_STATE, {
    [ActionTypes.LOAD_TASK]: loadTaskReducer,
    [ActionTypes.ADD_TASK]: addTaskReducer,
    [ActionTypes.UPDATE_TASK]: updateTaskReducer,
    [ActionTypes.DELETE_TASK]: deleteTaskReducer,
    [ActionTypes.BATCH_ADD_TASK]: batchAddTaskReducer,
    [ActionTypes.EXECUTE_TASK_ASYNC]: AsyncActionReducers.actionReducer,
    [ActionTypes.EXECUTE_TASK_SUCCESS]: AsyncActionReducers.successReducer,
    [ActionTypes.EXECUTE_TASK_FAILURE]: AsyncActionReducers.failureReducer,
});

export { ActionTypes, Actions, reducer, INITIAL_STATE };
