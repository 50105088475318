/**
 * Created by JIA on 2023/8/17.
 */
import React from "react";
import AppConfig from "../../config/AppConfig";
import DebugLogger from "../../utils/DebugLogger";
import { addError } from "../../utils/ErrorHelper";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // 更新状态，以便下一次渲染将显示后备 UI。
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        try {
            //this.setState({ hasError: true, errorMsg: error.name + ":" + error.message });
            //Bugsnag.notify(error);
            // Catch errors in any components below and re-render with error message
            //add error data into database
            let inVO = {
                errorMessage: error.name + ":" + error.message,
            };

            if (info.componentStack) {
                try {
                    inVO.errorPage = info.componentStack.toString();
                } catch (e) {
                    console.error(e);
                }
            }
            if (error.stack) {
                try {
                    inVO.errorTrace = error.stack.toString();
                } catch (e) {
                    console.error(e);
                }
            }

            addError(
                inVO,
                (response) => {
                    DebugLogger.log("add error data successful");
                },
                (err) => {
                    DebugLogger.log("add error data fail");
                }
            );
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        if (this.state.hasError) {
            // 你可以渲染任何自定义后备 UI
            return this.props.fallback ? this.props.fallback : null;
        }

        return this.props.children;
    }
}
