import React, { Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { Provider } from "react-redux";
import StoreManager from "./redux/";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/aichinese.scss";
import "./assets/css/portal-layout.css";

import "./assets/vendor/iconfont/iconfont.css";

import "react-toastify/dist/ReactToastify.min.css";

import DefaultScreen from "./screens/DefaultScreen";
import BackgroundTaskService from "./services/BackgroundTaskService";

// Fix: No animation registred by the name of none
import CustomAnimations from "./mobile/utils/CustomAnimations";

class App extends React.Component {
    constructor(props) {
        super(props);

        // create store when app starts
        this._store = StoreManager.getStore();
        BackgroundTaskService.startProcessing();
    }

    render() {
        return (
            <Provider store={this._store}>
                <Router>
                    <Suspense fallback={<div className="loader"></div>}>
                        <Route path="/" component={DefaultScreen} />
                    </Suspense>
                </Router>
            </Provider>
        );
    }
}

export default App;

// import React from "react"
// import { Form, Input, Select, CommonUnitFields } from "./components/ui/Form";

// export default function App() {

//     const onSubmit = data => {
//         console.log(data);
//     }

//     return (
//         <>
//             <h1>Smart Form Component</h1>
//             <Form onSubmit={onSubmit}>
//                 <CommonUnitFields name="commonUnitFields" />
//                 <Input name="firstName" />
//                 <Input name="lastName" />
//                 <Select name="sex" options={["female", "male"]} />
//                 {/* <input name="answer" type="text" ref={register({ required: true })} /> */}
//                 <Input type="submit" value="Submit" />
//             </Form>
//         </>
//     );
// }

// import React from "react"
// import { useForm } from "react-hook-form"

// function CommonUnitFields({ formMethods }) {
//     const { register, errors } = formMethods

//     CommonUnitFields.buildFormData = buildFormData;

//     const onSave = formData => {
//         console.log(formData);
//     };

//     return (
//         <>
//             <input name="title" ref={register({ required: true })} />
//             {errors.title && <span>This field is required</span>}
//             <input name="duration" ref={register} />
//         </>
//     );
// }

// function buildFormData(formData) {
//     return formData;
// };

// export default function App() {
//     const formMethods = useForm();
//     const { register, handleSubmit, watch, errors } = formMethods;
//     const onSubmit = data => {
//         var newData = CommonUnitFields.buildFormData(data);
//         console.log(data)
//     }

//     console.log(watch("example")) // watch input value by passing the name of it

//     return (
//         <form onSubmit={handleSubmit(onSubmit)} >
//             {/* register your input into the hook by invoking the "register" function */}
//             <input name="example" defaultValue="test" ref={register} />

//             {/* include validation with required or other standard HTML validation rules */}
//             <input name="exampleRequired" ref={register({ required: true })} />
//             {/* errors will return when field validation fails  */}
//             {errors.exampleRequired && <span>This field is required</span>}

//             <CommonUnitFields formMethods={formMethods} />

//             <input type="submit" />
//         </form >
//     )
// }
