import UIDs from "../../UIDs";

const uid = UIDs.session;

const ActionTypes = {
    LOGOUT: `${uid}.logout`,
    TRY_LOAD_SESSION: `${uid}.tryLoadSession`,
    RECEIVE_REFRESHED_TOKEN: `${uid}.receiveRefreshedToken`,
    SWITCH_BACK_TO_SUPER_ADMIN: `${uid}.switchBackToSuperAdmin`,
    SWITCH_BACK_TO_AGENT: `${uid}.switchBackToAgent`,
    SWITCH_BACK_TO_TEACHER: `${uid}.switchBackToTeacher`,
};

export { ActionTypes };
