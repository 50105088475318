import UIDs from "../../../../UIDs";

const uid = UIDs.screens.admin.chooseschool;

const ActionTypes = {
    SELECT_PARTNER: `${uid}.selectPartner`,

    LOGIN_OTHER_USER_ASYNC: `${uid}.loginOtherUserAsync`,
    LOGIN_OTHER_USER_SUCCESS: `${uid}.loginOtherUserSuccess`,
    LOGIN_OTHER_USER_FAILURE: `${uid}.loginOtherUserFailure`,
};

export { ActionTypes };
