import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ActionTypes as SessionActionTypes } from "./SessionRedux";

/* ------------- Initial State ------------- */

const INITIAL_STATE = Immutable({
});

/* ------------- Reducers ------------- */
const logoutReducer = (state) => {
    return state;
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [SessionActionTypes.LOGOUT]: logoutReducer,
});