import { toast } from "react-toastify";
import _ from "lodash";

// const defaultOptions = {
//     text: null,
//     position: null,
//     duration: null,
// };

// const mergeOptions = (options) => {
//     let baseOptions = {
//         ...defaultOptions,
//     };
//     if (_.isPlainObject(options)) {
//         return {
//             ...baseOptions,
//             ...options
//         };
//     } else if (_.isString(options)) {
//         return {
//             ...baseOptions,
//             text: options
//         };
//     } else {
//         return baseOptions;
//     }
// };

/**
 * a common components for show some alert message
 *  for now we just use the Toast in Native-base
 *  you can pass Toast options or just pass string
 * @type {{show: ((null?: *)), success: ((null?: *)), warning: ((null?: *)), danger: ((null?: *))}}
 */
const MessageBox = {
    show: options => {
        try {
            var settings = { };
            if (options.duration) {
                settings.autoClose = options.duration;
            }
            toast.info(_.isString(options) ? options: options.text, settings);
        } catch (e) {
            // ignore
        }
    },
    success: options => {
        try {
            var settings = { };
            if (options.duration) {
                settings.autoClose = options.duration;
            }
            toast.success(_.isString(options) ? options: options.text, settings);
        } catch (e) {
            // ignore
        }
    },
    warning: options => {
        try {
            var settings = { };
            if (options.duration) {
                settings.autoClose = options.duration;
            }
            toast.warn(_.isString(options) ? options: options.text, settings);
        } catch (e) {
            // ignore
        }
    },
    danger: options => {
        try {
            var settings = { };
            if (options.duration) {
                settings.autoClose = options.duration;
            }
            toast.error(_.isString(options) ? options: options.text, settings);
        } catch (e) {
            // ignore
        }
    }
};

export default MessageBox;
