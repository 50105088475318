import i18n from "i18next";
import { initReactI18next, withTranslation as orgWithTranslation } from "react-i18next";
import hoistNonReactStatics from 'hoist-non-react-statics';

import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import AppConfig from "./config/AppConfig";
import StorageEngine from "./utils/StorageEngine";
import moment from "moment";
import dayjs from "./utils/dayjs";
import "moment/locale/en-gb";
import "moment/locale/ko";
import "moment/locale/th";
import "moment/locale/zh-cn";

// // the translations
// // (tip move them in a JSON file and import them)
// const resources = {
//     en: {
//         translation: {
//             "Welcome to React": "Welcome to React and react-i18next"
//         }
//     }
// };
let language = navigator.language||navigator.userLanguage;
let lng = StorageEngine(AppConfig.LANGUAGE_SETTING).load();
let lngCode = lng && Object.keys(lng).length > 0 && lng !== "" ? lng : language;
lngCode = lngCode.substr(0, 2);

i18n
    // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
        backend: {
            // for all available options read the backend"s repository readme file
            // loadPath: "/locales/{{lng}}/{{ns}}.json"
            loadPath: function(lngArray, nsArray) {
                var language = lngArray[0];
                var namespace = nsArray[0]

                // break namespace into words like screens.account.login
                // so that we can build folder path to load the file
                const nsWords = namespace.split(".");

                var folderPath = "";
                for (var i = 0; i < nsWords.length - 1; i++) {
                    folderPath = folderPath + nsWords[i] + "/";
                }

                var fileName = nsWords.length > 1 ? nsWords[nsWords.length - 1] : namespace;

                return "/i18n/" + folderPath + fileName + "." + language + ".json?v=" + AppConfig.APP_CLIENT_BUILD;
            }
        },
        fallbackLng: "zh",
        // resources,
        lng: lngCode,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });
moment.locale(lngCode === "zh" ? "zh-cn" : lngCode);
dayjs.locale(lngCode);

/*Fix the withTranslation component static prop can not access issue */
function withTranslation(ns, options = {}) {
  return function Extend(WrappedComponent) {
    return hoistNonReactStatics(orgWithTranslation(ns, options)(WrappedComponent), WrappedComponent);
  }
}

export{ withTranslation };
export default i18n;
