import { g_paradux } from "./Paradux";

export default class ReducerRegister {

    // key: it is very important to be unique for all reducers since 
    //      it will be used to access the state managed by the reducer
    // reducer: the reducer functiont to update state
    constructor(key, reducer) {
        this.key = key;
        this.reducer = reducer;
        this.unsubscribeCount = 0;
    }

    register() {
        if (this.unsubscribeCount === 0) {
            var paradux = g_paradux.get();
            paradux.registerByKey(this.key, this.reducer);
        }
        this.unsubscribeCount++;
    }

    unregister() {
        this.unsubscribeCount--;
        if (this.unsubscribeCount === 0) {
            g_paradux.get().deregisterByKey(this.key);
        }
    }
}
