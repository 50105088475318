import UIDs from "../../../../UIDs";

const uid = UIDs.screens.account.userregister;

const ActionTypes = {
    REGISTER_ASYNC: `${uid}.registerAsync`,
    REGISTER_SUCCESS: `${uid}.registerSuccess`,
    REGISTER_FAILURE: `${uid}.registerFailure`,
    
    UPLOAD_IMAGE_TO_SERVER_ASYNC: `${uid}uploadImageToServerAsync`,
    UPLOAD_IMAGE_TO_SERVER_SUCCESS: `${uid}uploadImageToServerSuccess`,
    UPLOAD_IMAGE_TO_SERVER_FAILURE: `${uid}uploadImageToServerFailure`,

    SEND_VERIFY_CODE_ASYNC: `${uid}.sendVerifyCodeAsync`,
    SEND_VERIFY_CODE_SUCCESS: `${uid}.sendVerifyCodeSuccess`,
    SEND_VERIFY_CODE_FAILURE: `${uid}.sendVerifyCodeFailure`,

    CLOSE_COUNT_DOWN: `${uid}.closeCountDown`,
    SHOW_COUNT_DOWN: `${uid}.showCountDown`,
    CHANGE_SETTINGS: `${uid}.changeSettings`,
    HIDE_SEND_SUCCESSFULE_MSG: `${uid}.hideSendSuccessfulMsg`,

    RESET_TO_INITIAL_DATA:`${uid}.resetToInitialData`,
    LOGIN_ASYNC: `${uid}.loginAsync`,
    LOGIN_SUCCESS: `${uid}.loginSuccess`,
    LOGIN_FAILURE: `${uid}.loginFailure`,
};

export { ActionTypes };
