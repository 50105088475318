/**
 * Created by tomnotcat on 2022/12/05.
 */
import AppConfig from "../config/AppConfig";
import ApiService from "../services/ApiService";
import DebugLogger from "./DebugLogger";
import EventEmitter from "./EventEmitter";
import StorageEngine from "./StorageEngine";
import RegionServerTester from "./RegionServerTester";
import * as Helper from "./Helper";
import _ from "lodash";

const REGION_SERVER_STORAGE_KEY = "REGION_SERVER_STORAGE_KEY";

class RegionServerManager {
    constructor() {
        this._events = new EventEmitter();
        this._disabled = false;
        this._servers = [];
        this._selectedId = null;
        this._autoSelect = true;
        this._tester = new RegionServerTester();
        this._testResolves = [];
        this._onTestFinished = this._onTestFinished.bind(this);
        this._tester.events.addListener('finished', this._onTestFinished);
        this._loadPromise = null;
    }

    get events() {
        return this._events;
    }

    get servers() {
        return this._servers;
    }

    get selectedId() {
        return this._selectedId;
    }

    get autoSelect() {
        return this._autoSelect;
    }

    isEnabled() {
        const prefix = window.location.host.split('.')[0];
        if (prefix === 'www' || prefix === 'qa') {
            return true;
        }
        return false;
    }

    setDisabled(disabled) {
        this._disabled = disabled;
        if (!this._disabled) {
            let server = this._servers.find(it => it.id === this._selectedId);
            if (server) {
                this._setAppConfigServer(server);
            }
        }
    }

    getSelectedServer() {
        return this._servers.find(it => it.id === this._selectedId);
    }

    loadLocalStore() {
        if (this._loadPromise) {
            return this._loadPromise;
        }

        this._loadPromise = new Promise((resolve, reject) => {
            let loadStoreEnd = () => {
                if ((this._servers.length === 0) && AppConfig.LOCAL_REGION_SERVERS &&
                    (AppConfig.LOCAL_REGION_SERVERS.length > 0))
                {
                    this._servers = _.clone(AppConfig.LOCAL_REGION_SERVERS);

                    let server = this._servers.find(it => it.id === this._selectedId);
                    if (server) {
                        this._setAppConfigServer(server);
                    }
                    else {
                        this._selectedId = null;
                    }

                    if (this._selectedId === null) {
                        this._autoSelect = true;
                    }

                    this._events.emit("changed", {servers: this._servers,
                                                  selectedId: this._selectedId,
                                                  autoSelect: this._autoSelect});
                }

                if ((this._selectedId === null) && (this._autoSelect) && (this._servers.length > 0)) {
                    this.startTestAsync().then(resolve);
                }
                else {
                    resolve();
                }
            }

            StorageEngine(REGION_SERVER_STORAGE_KEY)
                .load()
                .then(response => {
                    if (response) {
                        if (this._servers.length === 0) {
                            this._servers = _.isArray(response.servers) ? response.servers : [];
                        }

                        let server = this._servers.find(it => it.id === response.selectedId);
                        if (server) {
                            this._selectedId = response.selectedId;
                            this._setAppConfigServer(server);
                        }
                        else {
                            this._selectedId = null;
                        }

                        this._autoSelect = !!response.autoSelect;
                        if (this._selectedId === null) {
                            this._autoSelect = true;
                        }

                        this._events.emit("changed", {servers: this._servers,
                                                      selectedId: this._selectedId,
                                                      autoSelect: this._autoSelect});
                    }

                    loadStoreEnd();
                })
                .catch(response => {
                    DebugLogger.log(response);
                    loadStoreEnd();
                });
        });

        return this._loadPromise;
    }

    clearLocalStore() {
        StorageEngine(REGION_SERVER_STORAGE_KEY).delete();
    }

    setServers(servers) {
        this._servers = _.clone(servers);
        if (this._selectedId !== null) {
            if (!this._servers.find(it => it.id === this._selectedId)) {
                this._selectedId = null;
            }
        }

        this._events.emit("changed", {servers: this._servers, selectedId: this._selectedId});
        this._saveToLocalStoreLater();
        this.stopTest();
        if (this._autoSelect && (this._servers.length > 0)) {
            this._startTestLater();
        }
        else if (this._selectedId !== null) {
            let server = this._servers.find(it => it.id === this._selectedId);
            if (server) {
                this._setAppConfigServer(server);
            }
        }
    }

    setSelectedId(id) {
        let server = this._servers.find(it => it.id === id);
        if (!server) {
            DebugLogger.log('invalid region server id: ' + id);
            return;
        }

        if (this._selectedId !== id) {
            this.stopTest();
            this._selectedId = id;
            this._events.emit("changed", {selectedId: this._selectedId});
            this._saveToLocalStoreLater();
            this._setAppConfigServer(server);
        } else {
            //still apply this since app load will override some default config, we need reapply
            this._setAppConfigServer(server);
        }
    }

    setAutoSelect(auto) {
        this._autoSelect = auto;
        this._events.emit("changed", {autoSelect: this._autoSelect});
        this._saveToLocalStoreLater();
        if (this._autoSelect) {
            this._startTestLater();
        }
        else {
            this.stopTest();
        }
    }

    startTest() {
        if (this._pendingTest !== undefined) {
            clearTimeout(this._pendingTest);
            this._pendingTest = undefined;
        }

        this._tester.start(this._servers, 3);
    }

    startTestAsync() {
        if (this._pendingTest !== undefined) {
            clearTimeout(this._pendingTest);
            this._pendingTest = undefined;
        }

        return new Promise((resolve, reject) => {
            this._testResolves.push(resolve);
            this._tester.start(this._servers, 3);
        });
    }

    stopTest() {
        this._tester.stop();
        if (this._pendingTest !== undefined) {
            clearTimeout(this._pendingTest);
            this._pendingTest = undefined;
        }
        this._resolveTests(null);
    }

    _setAppConfigServer(server) {
        if (!this.isEnabled()) {
            return;
        }

        if (this._disabled) {
            return;
        }

        if (!Helper.isNullOrEmpty(server.apiEndPoint)) {
            AppConfig.APP_SERVICE_BASE = server.apiEndPoint;

            ApiService.setBaseURL(AppConfig.APP_SERVICE_BASE);
        }

        if (!Helper.isNullOrEmpty(server.fileServerEndPoint)) {
            AppConfig.FILE_SERVER = server.fileServerEndPoint;
        }

        if (!Helper.isNullOrEmpty(server.proxyEndPoint)) {
            AppConfig.PROXY_SERVICE_BASE = server.proxyEndPoint;
        }

        if (!Helper.isNullOrEmpty(server.nodeServerEndPoint)) {
            AppConfig.NODE_SERVICE_BASE = server.nodeServerEndPoint;
        }

        if (!Helper.isNullOrUndefined(server.speechRecognizeServiceType) && server.speechRecognizeServiceType !== -1) {
            AppConfig.SPEECH_RECOGNIZE_SERVICE_TYPE = server.speechRecognizeServiceType;
        }

        if (!Helper.isNullOrUndefined(server.speechReviewServiceType) && server.speechReviewServiceType !== -1) {
            AppConfig.SPEECH_RECOGNIZE_SERVICE_REVIEW_TYPE = server.speechReviewServiceType;
        }

        if (!Helper.isNullOrUndefined(server.speechRecognizeUseProxyServer) && server.speechRecognizeUseProxyServer !== -1) {
            AppConfig.SPEECH_RECOGNIZE_USE_PROXY_SERVER = server.speechRecognizeUseProxyServer;
        }
    }

    _saveToLocalStoreLater() {
        if (this._pendingSave !== undefined) {
            return;
        }

        this._pendingSave = setTimeout(() => {
            this._pendingSave = undefined;
            StorageEngine(REGION_SERVER_STORAGE_KEY).save({
                servers: this._servers,
                selectedId: this._selectedId,
                autoSelect: this._autoSelect,
            });
        }, 500);
    }

    _startTestLater() {
        if (this._pendingTest !== undefined) {
            return;
        }

        this._pendingTest = setTimeout(() => {
            this._pendingTest = undefined;
            this.startTest();
        }, 500);
    }

    _resolveTests(result) {
        if (this._testResolves.length > 0) {
            let resolves = this._testResolves;
            this._testResolves = [];
            for (let i = 0; i < resolves.length; ++i) {
                resolves[i](result);
            }
        }
    }

    _onTestFinished(bestId) {
        if (this._autoSelect) {
            this.setSelectedId(bestId);
        }

        this._resolveTests(bestId);
    }
}

var g_regionServerServer = null;

RegionServerManager.instance = function() {
    if (!g_regionServerServer) {
        g_regionServerServer = new RegionServerManager();
    }
    return g_regionServerServer;
}

export default RegionServerManager;
