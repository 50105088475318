import UIDs from "../../../../UIDs";

const uid = UIDs.screens.account.registerpartner;

const ActionTypes = {
    REGISTER_PARTNER_ASYNC: `${uid}.registerPartnerAsync`,
    REGISTER_PARTNER_SUCCESS: `${uid}.registerPartnerSuccess`,
    REGISTER_PARTNER_FAILURE: `${uid}.registerPartnerFailure`,
    GETUSER_BY_EMAIL_ASYNC: `${uid}.getUserByEmailAsync`,
    GETUSER_BY_EMAIL_SUCCESS: `${uid}.getUserByEmailSuccess`,
    GETUSER_BY_EMAIL_FAILURE: `${uid}.getUserByEmailFailure`,
    SHOW_COUNT_DOWN: `${uid}.showCountDown`,
    SEND_CORP_CODE_ASYNC: `${uid}.sendCorpCodeAsync`,
    SEND_CORP_CODE_SUCCESS: `${uid}.sendCorpCodeSuccess`,
    SEND_CORP_CODE_FAILURE: `${uid}.sendCorpCodeFailure`,
    GET_ALL_COUNTRY_CODE_ASYNC:`${uid}.getAllCountryCodeAsync`,
    GET_ALL_COUNTRY_CODE_SUCCESS:`${uid}.getAllCountryCodeSuccess`,
    GET_ALL_COUNTRY_CODE_FAILURE:`${uid}.getAllCountryCodeFailure`,
    SEND_SMS_VERIFY_CODE_ASYNC:`${uid}.sendSmsVerifyCodeAsync`,
    SEND_SMS_VERIFY_CODE_SUCCESS:`${uid}.sendSmsVerifyCodeSuccess`,
    SEND_SMS_VERIFY_CODE_FAILURE:`${uid}.sendSmsVerifyCodeFailure`,
    SEND_EMAIL_VERIFY_CODE_ASYNC:`${uid}.sendEmailVerifyCodeAsync`,
    SEND_EMAIL_VERIFY_CODE_SUCCESS:`${uid}.sendEmailVerifyCodeSuccess`,
    SEND_EMAIL_VERIFY_CODE_FAILURE:`${uid}.sendEmailVerifyCodeFailure`,
    UPDATE_SETTINGS: `${uid}.updateSettings`,
    RESET_TO_INITIAL_DATA:`${uid}.resetToInitialData`,

    ACTIVE_TEACHER_ASYNC: `${uid}.activeTeacherAsync`,
    ACTIVE_TEACHER_SUCCESS: `${uid}.activeTeacherSuccess`,
    ACTIVE_TEACHER_FAILURE: `${uid}.activeTeacherFailure`,

    LOGIN_ASYNC: `${uid}.loginAsync`,
    LOGIN_SUCCESS: `${uid}.loginSuccess`,
    LOGIN_FAILURE: `${uid}.loginFailure`,
};

export { ActionTypes };
