import UIDs from "../../../../UIDs";

const ActionTypes = {
    LOGIN_ASYNC: `${UIDs.screens.account.login}.loginAsync`,
    LOGIN_SUCCESS: `${UIDs.screens.account.login}.loginSuccess`,
    LOGIN_FAILURE: `${UIDs.screens.account.login}.loginFailure`,

    WECHAT_LOGIN_ASYNC: `${UIDs.screens.account.login}.wechatLoginAsync`,
    WECHAT_LOGIN_SUCCESS: `${UIDs.screens.account.login}.wechatLoginSuccess`,
    WECHAT_LOGIN_FAILURE: `${UIDs.screens.account.login}.wechatLoginFailure`,

    FB_LOGIN_ASYNC: `${UIDs.screens.account.login}.fbLoginAsync`,
    FB_LOGIN_SUCCESS: `${UIDs.screens.account.login}.fbLoginSuccess`,
    FB_LOGIN_FAILURE: `${UIDs.screens.account.login}.fbLoginFailure`,

    GOOGLE_LOGIN_ASYNC: `${UIDs.screens.account.login}.googleLoginAsync`,
    GOOGLE_LOGIN_SUCCESS: `${UIDs.screens.account.login}.googleLoginSuccess`,
    GOOGLE_LOGIN_FAILURE: `${UIDs.screens.account.login}.googleLoginFailure`,

    ADMIN_LOGIN_SUCCESS:`${UIDs.screens.account.login}.adminLoginSuccess`,
};

export { ActionTypes };
