import UIDs from "../../../../UIDs";

const uid = UIDs.screens.me.profileSetting;
//
// ActionTypes
//
const ActionTypes = {
    LOAD_SCREEN_VIEW_ASYNC: `${uid}.loadScreenViewAsync`,
    LOAD_SCREEN_VIEW_SUCCESS: `${uid}.loadScreenViewSuccess`,
    LOAD_SCREEN_VIEW_FAILURE: `${uid}.loadScreenViewFailure`,

    CHANGE_NAME_ASYNC: `${uid}changeNameAsync`,
    CHANGE_NAME_SUCCESS: `${uid}changeNameSuccess`,
    CHANGE_NAME_FAILURE: `${uid}changeNameFailure`,

    CHANGE_GENDER_ASYNC: `${uid}changeGenderAsync`,
    CHANGE_GENDER_SUCCESS: `${uid}changeGenderSuccess`,
    CHANGE_GENDER_FAILURE: `${uid}changeGenderFailure`,

    CHANGE_BIRTHDAY_ASYNC: `${uid}changeBirthdayAsync`,
    CHANGE_BIRTHDAY_SUCCESS: `${uid}changeBirthdaySuccess`,
    CHANGE_BIRTHDAY_FAILURE: `${uid}changeBirthdayFailure`,

    CHANGE_MEMBER_PHOTO_ASYNC: `${uid}changeMemberPhotoAsync`,
    CHANGE_MEMBER_PHOTO_SUCCESS: `${uid}changeMemberPhotoSuccess`,
    CHANGE_MEMBER_PHOTO_FAILURE: `${uid}changeMemberPhotoFailure`,

    CHANGE_ROLE_ASYNC: `${uid}changeRoleAsync`,
    CHANGE_ROLE_SUCCESS: `${uid}changeRoleSuccess`,
    CHANGE_ROLE_FAILURE: `${uid}changeRoleFailure`,

    UPLOAD_IMAGE_TO_SERVER_ASYNC: `${uid}uploadImageToServerAsync`,
    UPLOAD_IMAGE_TO_SERVER_FAILURE: `${uid}uploadImageToServerFailure`,

    LINK_WECHAT_ASYNC: `${uid}.linkWechatAsync`,
    LINK_WECHAT_SUCCESS: `${uid}.linkWechatSuccess`,
    LINK_WECHAT_FAILURE: `${uid}.linkWechatFailure`,

    UNLINK_WECHAT_ASYNC: `${uid}.unlinkWechatAsync`,
    UNLINK_WECHAT_SUCCESS: `${uid}.unlinkWechatSuccess`,
    UNLINK_WECHAT_FAILURE: `${uid}.unlinkWechatFailure`,

    LINK_FACEBOOK_ASYNC: `${uid}.linkFacebookAsync`,
    LINK_FACEBOOK_SUCCESS: `${uid}.linkFacebookSuccess`,
    LINK_FACEBOOK_FAILURE: `${uid}.linkFacebookFailure`,

    UNLINK_FACEBOOK_ASYNC: `${uid}.unlinkFacebookAsync`,
    UNLINK_FACEBOOK_SUCCESS: `${uid}.unlinkFacebookSuccess`,
    UNLINK_FACEBOOK_FAILURE: `${uid}.unlinkFacebookFailure`,

    LINK_GOOGLE_ASYNC: `${uid}.linkGoogleAsync`,
    LINK_GOOGLE_SUCCESS: `${uid}.linkGoogleSuccess`,
    LINK_GOOGLE_FAILURE: `${uid}.linkGoogleFailure`,

    UNLINK_GOOGLE_ASYNC: `${uid}.unlinkGoogleAsync`,
    UNLINK_GOOGLE_SUCCESS: `${uid}.unlinkGoogleSuccess`,
    UNLINK_GOOGLE_FAILURE: `${uid}.unlinkGoogleFailure`,

    UPDATE_LINK_SETTINGS: `${uid}.updateLinkSettings`,

    CREATE_PLACED_ORDER_ASYNC: `${uid}.createPlacedOrderAsync`,
    CREATE_PLACED_ORDER_SUCCESS: `${uid}.createPlacedOrderSuccess`,
    CREATE_PLACED_ORDER_FAILURE: `${uid}.createPlacedOrderFailure`,
};

export { ActionTypes };
