import { createReducer } from "reduxsauce";
import _ from "lodash";

import { ActionTypes as TeacherLoginActionTypes } from "./actionTypes/screens/account/TeacherLoginActionTypes";
import { ActionTypes as ProfileActionTypes } from "./actionTypes/screens/admin/ProfileActionTypes";
import { ActionTypes as MobileProfileSettingActionTypes } from "../mobile/redux/actionTypes/screens/me/ProfileSettingActionTypes";
import { ActionTypes as AdminActionTypes } from "./actionTypes/layouts/AdminActionTypes";
import { ActionTypes as ChooseSchoolActionTypes } from "./actionTypes/screens/admin/ChooseSchoolActionTypes";
import { ActionTypes as RegisterPartnerActionTypes } from "./actionTypes/screens/account/RegisterPartnerActionTypes";
import { ActionTypes as LoginActionTypes } from "./actionTypes/screens/account/LoginActionTypes";
import { ActionTypes as MobileLoginActionTypes } from "../mobile/redux/actionTypes/screens/account/LoginActionTypes";
import { ActionTypes as UserLoginActionTypes } from "./actionTypes/screens/account/UserLoginActionTypes";
import { ActionTypes as UserRegisterActionTypes } from "./actionTypes/screens/account/UserRegisterActionTypes";


import AppConfig from "../config/AppConfig";
import StorageEngine from "../utils/StorageEngine";
import { ActionTypes } from "./actionTypes/SessionActionTypes";
import Constants from "../config/Constants";

//
// Actions
//
const Actions = {
    tryLoadSession: () => ({
        type: ActionTypes.TRY_LOAD_SESSION
    }),

    logout: () => ({
        type: ActionTypes.LOGOUT
    }),

    receiveRefreshedToken: data => ({
        type: ActionTypes.RECEIVE_REFRESHED_TOKEN,
        data
    }),

    switchBackToSuperAdmin: data => ({
        type: ActionTypes.SWITCH_BACK_TO_SUPER_ADMIN,
        data
    }),

    switchBackToAgent: (data, isLoginFromAdminStatic, superAdminBackupData) => ({
        type: ActionTypes.SWITCH_BACK_TO_AGENT,
        data,
        isLoginFromAdminStatic,
        superAdminBackupData,
    }),

    switchBackToTeacher: (data, isLoginFromAdminStatic, superAdminBackupData, isLoginFromAgent, agentBackupData) => ({
        type: ActionTypes.SWITCH_BACK_TO_TEACHER,
        data,
        isLoginFromAdminStatic,
        superAdminBackupData,
        isLoginFromAgent,
        agentBackupData,
    }),
};

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
    user: null,
    authorization: null,
    userId: null,
    partnerList: null,
    partner: null,
    isLoginFromSuperAdmin: false,
    superAdminBackupData: null,
    agentBackupData: null,
    teacherBackupData: null,
};

/* ------------- Reducers ------------- */
// we've successfully logged in
const loginSuccessReducer = (state, { data }) => {
    const baseState = {
        user: data.user,
        authorization: data.authorization,
        userId: data.user.id,
        partnerList: data.partnerList,
        partner: data.partnerList && data.partnerList.length > 0 ? data.partnerList[0] : null, // by default, select first one
        unitTaskParticipants: data.unitTaskParticipants,
        isLoginFromSuperAdmin: data.isLoginFromSuperAdmin,
        isLoginFromAgent: data.isLoginFromAgent,
        isLoginFromAdminStatic: data.isLoginFromAdminStatic,
        isLoginFromTeacher: data.isLoginFromTeacher,
    };
    const newState = {
        ...baseState,
        superAdminBackupData: data.isLoginFromSuperAdmin || data.isLoginFromAdminStatic  ? data.superAdminBackupData ? data.superAdminBackupData : { user: state.user, userId: state.userId, authorization: state.authorization, partnerList: state.partnerList, partner: state.partner } : null,
        agentBackupData: data.isLoginFromAgent ? data.agentBackupData ? data.agentBackupData : { user: state.user, userId: state.userId, authorization: state.authorization, partnerList: state.partnerList, partner: state.partner } : null,
        teacherBackupData: data.isLoginFromTeacher ? data.teacherBackupData ? data.teacherBackupData : { user: state.user, userId: state.userId, authorization: state.authorization, partnerList: state.partnerList, partner: state.partner } : null,
    };

	if (AppConfig.ENABLE_LEARN_FUNCTION !== data.enableLearnFunction)
        AppConfig.updateAppConfig({ ENABLE_LEARN_FUNCTION: data.enableLearnFunction });

	if (AppConfig.ENABLE_CHINESE_PARK !== data.enableChinesePark)
        AppConfig.updateAppConfig({ ENABLE_CHINESE_PARK: data.enableChinesePark });

	if (AppConfig.ENABLE_BBS_FUNCTION !== data.enableBbsFunction)
        AppConfig.updateAppConfig({ ENABLE_BBS_FUNCTION: data.enableBbsFunction });

    //save into storage
    StorageEngine(AppConfig.SESSION_STORAGE_KEY).save(newState);

    return { ...state, ...newState };
};

const logoutReducer = (state) => {
    //delete from store
    StorageEngine(AppConfig.SESSION_STORAGE_KEY).delete();
    // we need to keep some values like isConnected or else, so
    // here we only override necessary states when logout
    return { ...state, ...INITIAL_STATE };
};

const tryLoadSessionReducer = (state) => {
    const newState = StorageEngine(AppConfig.SESSION_STORAGE_KEY).load();
    if (!_.isNull(newState) && !_.isUndefined(newState)) {
        return { ...state, ...newState };
    } else {
        return state;
    }
};

const receiveRefreshedTokenReducer = (state, { data }) => {
    const newState = {
        authorization: { ...data }
    };

    //save into storage
    StorageEngine(AppConfig.SESSION_STORAGE_KEY).save({ ...state, ...newState });

    return { ...state, ...newState };
};

// we've successfully selected one partner
const selectPartnerSuccessReducer = (state, { data }) => {
    const newState = {
        partner: data
    };

    //save into storage
    StorageEngine(AppConfig.SESSION_STORAGE_KEY).save({ ...state, ...newState });

    return { ...state, ...newState };
};

const saveUserSuccessReducer = (state, { data }) => {
    const newUser = {
        user: data,
        userId: data.id,
    };

    let newPartner = { ...state.partner, organization: data.organization };
    let newPartnerList = _.cloneDeep(state.partnerList);
    if (!_.isNull(newPartnerList)) {
        let partnerItem = newPartnerList.find(item => item.id === newPartner.id && item.id !== "");
        if (partnerItem) {
            partnerItem.name = data.organization;
        }
        newPartnerList.filter(item => item.addedBy === data.addedBy).forEach(item => item.fullName = data.creator);
    }

    //save into storage
    StorageEngine(AppConfig.SESSION_STORAGE_KEY).save({ ...state, ...newUser, partner: newPartner, partnerList: newPartnerList });

    return { ...state, ...newUser, partner: newPartner, partnerList: newPartnerList };
};

const saveUserPhotoSuccessReducer = (state, { newPhoto }) => {
    const newUserIcon = {...state.user.icon, photo: newPhoto};
    const newUser = {...state.user, icon: newUserIcon};

    //save into storage
    StorageEngine(AppConfig.SESSION_STORAGE_KEY).save({ ...state, user: newUser});

    return { ...state, user: newUser };
};

const switchBackToSuperAdminReducer = (state, { data }) => {
    const newState = {
        ...data,
        user: data.user,
        userId: data.userId,
        authorization: data.authorization,
        superAdmin: true,
        isLoginFromSuperAdmin: false,
        isLoginFromAgent: false,
        isLoginFromAdminStatic: false,
        isLoginFromTeacher: false,
    };
    //save into storage
    StorageEngine(AppConfig.SESSION_STORAGE_KEY).save({ ...state, ...newState });

    return { ...state, ...newState };
};

const switchBackToAgentReducer = (state, { data, isLoginFromAdminStatic, superAdminBackupData }) => {
    const newState = {
        ...data,
        user: data.user,
        userId: data.userId,
        authorization: data.authorization,
        superAdmin: true,
        isLoginFromAgent: false,
        isLoginFromAdminStatic,
        superAdminBackupData,
        isLoginFromTeacher:false,
    };
    //save into storage
    StorageEngine(AppConfig.SESSION_STORAGE_KEY).save({ ...state, ...newState });

    return { ...state, ...newState };
};

const switchBackToTeacherReducer = (state, { data, isLoginFromAdminStatic, superAdminBackupData, isLoginFromAgent, agentBackupData }) => {
    const newState = {
        ...data,
        user: data.user,
        userId: data.userId,
        authorization: data.authorization,
        superAdmin: true,
        isLoginFromAgent,
        isLoginFromAdminStatic,
        superAdminBackupData,
        agentBackupData,
        isLoginFromTeacher: false
    };

    //save into storage
    StorageEngine(AppConfig.SESSION_STORAGE_KEY).save({ ...state, ...newState });

    return { ...state, ...newState };
};

/* ------------- Hookup Reducers To Types ------------- */

const reducer = createReducer(INITIAL_STATE, {
    [TeacherLoginActionTypes.LOGIN_SUCCESS]: loginSuccessReducer,
    [UserLoginActionTypes.LOGIN_SUCCESS]: loginSuccessReducer,
    //[RegisterPartnerActionTypes.REGISTER_PARTNER_SUCCESS]: loginSuccessReducer,
    [RegisterPartnerActionTypes.LOGIN_SUCCESS]: loginSuccessReducer,
    //[UserRegisterActionTypes.REGISTER_SUCCESS]: loginSuccessReducer,
    [UserRegisterActionTypes.LOGIN_SUCCESS]: loginSuccessReducer,
    [LoginActionTypes.LOGIN_SUCCESS]: loginSuccessReducer,
    [MobileLoginActionTypes.LOGIN_SUCCESS]: loginSuccessReducer,
    [RegisterPartnerActionTypes.ACTIVE_TEACHER_SUCCESS]: loginSuccessReducer,
    [AdminActionTypes.SELECT_PARTNER]: selectPartnerSuccessReducer,
    [ChooseSchoolActionTypes.SELECT_PARTNER]: selectPartnerSuccessReducer,
    [ActionTypes.TRY_LOAD_SESSION]: tryLoadSessionReducer,
    [ActionTypes.LOGOUT]: logoutReducer,
    [ActionTypes.RECEIVE_REFRESHED_TOKEN]: receiveRefreshedTokenReducer,
    [ActionTypes.SWITCH_BACK_TO_SUPER_ADMIN]: switchBackToSuperAdminReducer,
    [ActionTypes.SWITCH_BACK_TO_AGENT]: switchBackToAgentReducer,
    [ActionTypes.SWITCH_BACK_TO_TEACHER]: switchBackToTeacherReducer,
    [ProfileActionTypes.SAVE_USER_SUCCESS]: saveUserSuccessReducer,
    [MobileProfileSettingActionTypes.CHANGE_MEMBER_PHOTO_SUCCESS]: saveUserPhotoSuccessReducer,
    [LoginActionTypes.CHANGE_PASSWORD_SUCCESS]: loginSuccessReducer,
});

export { ActionTypes, Actions, reducer, INITIAL_STATE };
