/**
 * Created by tomnotcat on 2022/12/06.
 */
import AppConfig from "../config/AppConfig";
import DebugLogger from "./DebugLogger";
import EventEmitter from "./EventEmitter";
import _ from "lodash";
const base64 = require("base-64");

class TestResult {
    constructor(rtt) {
        this.rtt = rtt;
    }
}

class TestTask {
    constructor(host, item) {
        this.host = host;
        this.item = item;
    }

    _onResult(rtt) {
        if (this.host) {
            let r = new TestResult(rtt);
            this.host._onTestResult(this, r);
            this.host = null;
        }

        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = undefined;
        }
    }

    start() {
        const hash = base64.encode(AppConfig.APP_CLIENT_ID + ":" + AppConfig.APP_CLIENT_SECRET);
        const basicAuth = "Basic " + hash;
        const url = this.item.apiEndPoint + '/app/ping';
        this._begin = Date.now();
        fetch(url, {
            method: "POST",
            headers: {
                "Cache-Control": "no-cache",
                "Authorization": basicAuth,
            },
        }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.isDone) {
                    this._onResult(Date.now() - this._begin);
                }
                else {
                    console.warn('ping error: ', responseData);
                    this._onResult(-1);
                }
            })
            .catch((err) => {
                this._onResult(-1);
            });

        this._timer = setTimeout(() => {
            this._onResult(-1);
        }, 10000);
    }
}

class RegionServerTester {
    constructor() {
        this._events = new EventEmitter();
        this._servers = [];
        this._results = new Map();
        this._pendings = [];
        this._waitings = [];
        this._concurrent = 10;
        this._maxRound = 0;
        this._curRound = 0;
        this._roundDelay = 0;
        this._started = false;
    }

    get events() {
        return this._events;
    }

    start(servers, maxRound, roundDelay) {
        this._stopTest();
        this._results.clear();
        this._servers = _.clone(servers);
        this._maxRound = maxRound || 0;
        this._curRound = 0;
        this._roundDelay = roundDelay || 0;
        this._startTest();
    }

    stop() {
        this._stopTest();
    }

    getBestId() {
        let bestId = null;
        let bestScore = 0;
        let maxRttTime = this._getMaxRttTime();
        this._results.forEach((val, key) => {
            let score = this._getScore(val, maxRttTime);
            if (score > 0) {
                if ((bestId === null) || (score > bestScore)) {
                    bestId = key;
                    bestScore = score;
                }
            }
        });
        return bestId;
    }

    getScore(serverId) {
        return this._getScore(this._results.get(serverId), this._getMaxRttTime());
    }

    _getMaxRttTime() {
        let maxRttTime = 1000;
        this._results.forEach((val, key) => {
            for (let i = 0; i < val.length; ++i) {
                if (val[i].rtt > maxRttTime) {
                    maxRttTime = val[i].rtt;
                }
            }
        });
        return maxRttTime;
    }

    _getScore(results, maxRttTime) {
        if (!results) {
            return -1;
        }

        if (results.length < 1) {
            return -1;
        }

        let totalTime = 0;
        let totalCount = 0;
        let timeoutCount = 0;
        for (let i = 0; i < results.length; ++i) {
            if (results[i].rtt >= 0) {
                totalTime += results[i].rtt;
                totalCount += 1;
            }
            else {
                timeoutCount += 1;
            }
        }

        if (totalCount < 1) {
            return 0;
        }

        let baseScore = parseInt(totalCount * 70 / results.length);
        let timeScore = maxRttTime - totalTime / totalCount;
        if (timeScore < 0) {
            timeScore = 0;
        }

        timeScore = parseInt(timeScore * 30 / maxRttTime);
        return baseScore + timeScore;
    }

    _startTest() {
        if (this._started) {
            return;
        }

        this._started = true;
        while (this._nextPing());
    }

    _stopTest() {
        this._pendings = [];
        this._waitings = [];
        this._started = false;
        this._nextRoundTime = undefined;
        if (this._nextRoundTimer) {
            clearInterval(this._nextRoundTimer);
            this._nextRoundTimer = undefined;
        }
    }

    _nextPing() {
        if (!this._started) {
            return false;
        }

        if (this._pendings.length >= this._concurrent) {
            return false;
        }

        if (this._waitings.length === 0) {
            if ((this._roundDelay > 0) && (this._curRound > 0) && (!this._nextRoundTime)) {
                this._nextRoundTime = Date.now() + this._roundDelay;
                if (!this._nextRoundTimer) {
                    this._nextRoundTimer = setInterval(() => {
                        if (Date.now() >= this._nextRoundTime) {
                            this._nextPing();
                        }
                    }, 200);
                }
            }

            if (this._nextRoundTime) {
                if (Date.now() < this._nextRoundTime) {
                    return false;
                }

                this._nextRoundTime = undefined;
                if (this._nextRoundTimer) {
                    clearInterval(this._nextRoundTimer);
                    this._nextRoundTimer = undefined;
                }
            }

            if ((this._curRound < this._maxRound) || (this._maxRound <= 0)) {
                this._curRound += 1;
                this._waitings = _.clone(this._servers);
            }
        }

        if (this._waitings.length === 0) {
            return false;
        }

        var item = this._waitings.shift();
        var task = new TestTask(this, item);
        this._pendings.push(task);
        task.start();
        return true;
    }

    _onTestResult(task, result) {
        let idx = this._pendings.indexOf(task);
        if (idx >= 0) {
            this._pendings.splice(idx, 1);
            let rr = this._results.get(task.item.id);
            if (rr) {
                rr.push(result);
                if (rr.length > 20) {
                    rr.shift();
                }
            }
            else {
                rr = [result];
                this._results.set(task.item.id, rr);
            }

            if (this._maxRound > 0) {
                if (rr.length >= this._maxRound) {
                    let hasTimeout = false;
                    for (let i = 0; i < rr.length; ++i) {
                        if (rr[i].rtt < 0) {
                            hasTimeout = true;
                            break;
                        }
                    }

                    if (!hasTimeout) {
                        this._stopTest();
                        this._events.emit("finished", task.item.id);
                        return;
                    }
                }
            }

            while (this._nextPing());
            if (this._pendings.length > 0 || (this._nextRoundTime !== undefined)) {
                this._events.emit("ping", task.item.id, result);
            }
            else {
                let bestId = this.getBestId();
                this._stopTest();
                this._events.emit("finished", bestId);
            }
        }
    }
}

export default RegionServerTester;
