import PropTypes from "prop-types";

import * as Helper from "../../utils/Helper";
import BaseComponent from "./BaseComponent";

class BaseScreen extends BaseComponent {
    get viewName() {
        return this.routeName;
    }

    get routeName() {
        throw new Error("Not implemented.");
    }

    get callUpHandlers() {
        return {};
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    isGuest = Helper.isGuest;

    isLoggedIn = Helper.isLoggedIn;
}

BaseScreen.propTypes = {
    userId: PropTypes.string,
};

BaseScreen.isGuest = Helper.isGuest;
BaseScreen.isLoggedIn = Helper.isLoggedIn;

export default BaseScreen;
