export class Paradux {

    constructor(initialReducers = [], rootKey) {
        this._reducers = initialReducers;
        this._rootKey = rootKey;
        this._keyedReducers = {};
        this._registry = {};

        this.reducerWrapper = this.reducerWrapper.bind(this);
        this.register = this.register.bind(this);
        this.deregister = this.deregister.bind(this);
    }

    reducerWrapper(initReducers = []) {
        this._reducers = this._reducers.concat(initReducers);

        return (state, action) => {
            var nextState = this._reducers.reduce((collectiveState, reducer) => {
                return reducer(collectiveState, action);
            }, state);

            if (nextState === state) {
                nextState = { ...state };
            }

            const reducerKeys = Object.keys(this._keyedReducers);
            const rootState = nextState[this._rootKey];
            const nextRootState = {};
            for (let i = 0; i < reducerKeys.length; i++) {
                const key = reducerKeys[i];
                const reducer = this._keyedReducers[key];
                if (!reducer) {
                    continue;
                }

                const previousStateForKey = rootState[key];
                const nextStateForKey = reducer(previousStateForKey, action);
                if (typeof nextStateForKey === "undefined") {
                    const errorMessage = getUndefinedStateErrorMessage(key, action);
                    throw new Error(errorMessage);
                }
                nextRootState[key] = nextStateForKey;
            }

            nextState[this._rootKey] = nextRootState;
            return nextState;
        };
    }

    register(reducer, namespace) {
        this._reducers.push(reducer);

        var deregister = this.deregister(reducer, namespace);

        if (namespace) {
            this._registry[namespace] = {
                reducer,
                deregister
            };
        }

        return deregister;
    }

    registerByKey(key, reducer) {
        this._keyedReducers[key] = reducer;
    }

    deregisterByKey(key) {
        this._keyedReducers[key] = null;
    }

    deregister(reducer, namespace) {
        return () => {
            this._reducers.splice(this._reducers.indexOf(reducer), 1);

            if (namespace) {
                delete this._registry[namespace];
            }

            return true;
        };
    }

    deregisterByNamespace(namespace) {
        this._registry[namespace].deregister();

        delete this._registry[namespace];

        return true;
    }
}

function getUndefinedStateErrorMessage(key, action) {
    const actionType = action && action.type;
    const actionName = (actionType && `"${actionType.toString()}"`) || "an action";

    return (
        `Given action ${actionName}, reducer "${key}" returned undefined. ` +
        "To ignore an action, you must explicitly return the previous state. " +
        "If you want this reducer to hold no value, you can return null instead of undefined."
    );
}

var _internalParadux = null;

export const g_paradux = {

    get: () => {
        return _internalParadux;
    },

    set: (val) => {
        _internalParadux = val;
    }
};