var EventEmitter = function() {
    this._events = new Map();
    this._emitting = new Map();
}

EventEmitter.prototype.on =
EventEmitter.prototype.addListener = function(event, listener) {
    var item = this._events.get(event);
    if (item) {
        item.push(listener);
    }
    else {
        this._events.set(event, [listener]);
    }
};

EventEmitter.prototype.off =
EventEmitter.prototype.removeListener = function(event, listener) {
    var item = this._events.get(event);
    if (item) {
        var length = item.length;
        var found = false;
        for (var i = length - 1; i >= 0; i--) {
            if (item[i] === listener) {
                found = true;
                if (this._emitting.get(event)) {
                    delete item[i];
                }
                else {
                    item.splice(i, 1);
                }
                break;
            }
        }

        if (0 === item.length) {
            this._events.delete(event);
        }

        if (!found) {
            console.warn("no listener found: " + event);
        }
    }
    else {
        console.warn("no event found: " + event);
    }
};

EventEmitter.prototype.removeAllListeners = function(event) {
    if (event) {
        this._events.delete(event);
    }
    else {
        this._events.clear();
    }
};

EventEmitter.prototype.events = function() {
    return this._events.keys();
};

EventEmitter.prototype.listeners = function(event) {
    return this._events.get(event) || [];
};

EventEmitter.prototype.hasListeners = function(event) {
    return !! this.listeners(event).length;
};

EventEmitter.prototype.emit = function(event) {
    var args = [].slice.call(arguments, 1);
    var item = this._events.get(event);
    if (item) {
        var length = item.length;
        var hasnull = false;
        this._emitting.set(event, true);
        for (var i = 0; i < length; ++i) {
            if (item[i]) {
                item[i].apply(this, args);
            }
            else {
                hasnull = true;
            }
        }
        this._emitting.delete(event);
        if (hasnull) {
            item = item.filter(h => h);
            if (0 === item.length) {
                this._events.delete(event);
            }
            else {
                this._events.set(event, item);
            }
        }
    }
};

export default EventEmitter;
