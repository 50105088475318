class ReactNativeWebDimensionHelper {
    constructor() {}

    get window() {
        return {
            width: 1920,
            height: 1080 - 60,
        };
    }

    get(p) {
        return {
            width: 1920,
            height: 1080 - 60,
        };
    }
}

const DimensionsHelper = new ReactNativeWebDimensionHelper();

export default DimensionsHelper;
