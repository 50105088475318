import { createStore, applyMiddleware, compose, combineReducers } from "redux";
// import { createLogger } from "redux-logger";
import reduxMiddleware from "react-block-ui/lib/reduxMiddleware";
import thunk from "redux-thunk";

import { Paradux, g_paradux } from "./Paradux";
import screenTracking from "./middleware/ScreenTracking";

class StoreManager {

    constructor() {
        this.store = null;
    }

    getStore() {
        if (!this.store) {
            this.store = this._createStore();
        }

        return this.store;
    }

    _createStore() {
        const appReducer = combineReducers({ session: require("./SessionRedux").reducer, screens: require("./ScreensRedux").reducer, actions: (state = [], payload) => [payload, ...state], task: require("./TaskRedux").reducer });

        // set global paradux instance
        g_paradux.set(new Paradux([appReducer], "screens"));

        const rootReducer = g_paradux.get().reducerWrapper();
        return this._configureStore(rootReducer);
    }

    // creates the store
    _configureStore(rootReducer) {
        /* ------------- Redux Configuration ------------- */

        const middleware = [];
        const enhancers = [];

        middleware.push(thunk);
        middleware.push(screenTracking);
        middleware.push(reduxMiddleware);
        // // middleware.push(createLogger());

        // /* ------------- Assemble Middleware ------------- */

        enhancers.push(applyMiddleware(...middleware));

        /* ------------- AutoRehydrate Enhancer ------------- */

        var store = null;
        // if (__DEV__) {
        //     store = Reactotron.createStore(rootReducer, compose(...enhancers));
        // } else {
        store = createStore(rootReducer, compose(...enhancers));
        // }

        return store;
    }
}

export default new StoreManager();
